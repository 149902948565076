import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

import { ArrowRightIcon, Typography } from '@letsdance/ui-kit';
import { DANCE_WIKI_CARD } from '@widgets/Landing/const/main-text.const';

import cls from './styles.module.scss';

export const DanceWiki: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <section className={cls.section}>
      <header className={cls.header}>
        <Typography
          variant={isDesktop ? 'head-60' : 'head-32'}
          color={'neutrals-1'}>
          Танцевальная Wikipedia
        </Typography>
        <Typography
          variant={'body-20'}
          className={cls.subtitle}
          color={'on-primary-1'}>
          Бесплатная энциклопедия для всех любителей сальсы, бачаты и кизомбы
        </Typography>
      </header>
      <div className={cls.wrapper}>
        {DANCE_WIKI_CARD.map((item, i) => (
          <div
            key={item}
            data-number={i + 1}
            className={cx(cls.card, cls[`card_${i + 1}`])}>
            <Typography
              variant={'body-20'}
              className={cls.text}
              color={'on-primary-1'}>
              <span dangerouslySetInnerHTML={{ __html: item || '' }}></span>
            </Typography>
          </div>
        ))}
      </div>
      <Typography
        variant={isDesktop ? 'head-24' : 'head-16'}
        color={'on-primary-1'}
        className={cls.btn}
        onClick={() => {
          window.open(
            'https://t.me/h2dance_bot',
            '_blank',
            'noopener,noreferrer',
          );
        }}>
        Перейти в Wikipedia <ArrowRightIcon />
      </Typography>
    </section>
  );
};
