import React, { FC } from 'react';
import { Link } from 'react-justanother-router';

import { RoleEnum, useMeQuery } from '@/apolloGenerated';
import { HomeRouteName } from '@app/router';

import styles from './styles.module.scss';

export const NotFoundView: FC = (): JSX.Element => {
  const { data } = useMeQuery();

  return (
    <div className={styles.staticPage404}>
      <div className={styles.staticPage404__textWrapper}>
        <h1 className={styles.staticPage404__title}>404</h1>
        <h3 className={styles.staticPage404__subtitle}>Страница не найдена</h3>
      </div>
      <div className={styles.staticPage404__footer}>
        <p>Вернуться на</p>
        <Link
          className={styles.staticPage404__link}
          to={HomeRouteName[data?.me.role || RoleEnum.User]}>
          Главную
        </Link>
      </div>
    </div>
  );
};
