import { gql } from '@apollo/client';

export const orderFragment = gql`
  fragment OrderFields on OrderType {
    createdAt
    updatedAt
    deletedAt
    id
    completed
    expired_date
    price
    teacherCommission
    status
    invoice_url
  }
`;
export const ordersList = gql`
  ${orderFragment}
  query ordersList(
    $filter: OrdersListFilterInput
    $teacherId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
    $isExpired: Boolean
  ) {
    ordersList(
      filters: $filter
      args: {
        teacherId: $teacherId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: Success, completed: $isExpired }
    ) {
      count
      rows {
        ...OrderFields
        user {
          first_name
          last_name
          email
          username
          uuid
          phone
        }
        product {
          name
          type
        }
        tariff {
          name
        }
      }
    }
  }
`;

export const ordersCount = gql`
  query ordersCount(
    $filter: OrdersListFilterInput
    $teacherId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
    $isExpired: Boolean
  ) {
    ordersList(
      filters: $filter
      args: {
        teacherId: $teacherId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: Success, completed: $isExpired }
    ) {
      count
    }
  }
`;

export const ordersAwaitSubmitList = gql`
  ${orderFragment}
  query ordersAwaitSubmitList(
    $filter: OrdersListFilterInput
    $teacherId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
  ) {
    ordersList(
      filters: $filter
      args: {
        teacherId: $teacherId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: Pending, type: ManualPay }
    ) {
      count
      rows {
        ...OrderFields
        proof {
          file
        }
        user {
          first_name
          last_name
          email
          username
          uuid
          phone
        }
        product {
          name
          type
        }
        tariff {
          name
        }
      }
    }
  }
`;

export const ordersAwaitSubmitCount = gql`
  query ordersAwaitSubmitCount(
    $teacherId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
  ) {
    ordersList(
      filters: { pagination: { pageSize: 1, page: 1 } }
      args: {
        teacherId: $teacherId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: Pending, type: ManualPay }
    ) {
      count
    }
  }
`;

export const submitManualOrder = gql`
  mutation submitManualOrder($orderId: Float!) {
    submitManualOrder(orderId: $orderId) {
      id
    }
  }
`;

export const cancelManualOrder = gql`
  mutation cancelManualOrder($orderId: Float!) {
    cancelManualOrder(orderId: $orderId) {
      id
    }
  }
`;
