import { FC } from 'react';

export const HeartIcon: FC = () => (
  <svg
    width="181"
    height="180"
    viewBox="0 0 181 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="40.6667"
      y="40"
      width="100"
      height="100"
      rx="50"
      fill="url(#paint0_linear_3838_41581)"
      fillOpacity="0.3"
    />
    <rect
      x="41.6667"
      y="41"
      width="98"
      height="98"
      rx="49"
      stroke="white"
      strokeOpacity="0.5"
      strokeWidth="2"
    />
    <g opacity="0.6" filter="url(#filter0_f_3838_41581)">
      <ellipse
        cx="90.6667"
        cy="90"
        rx="50"
        ry="50"
        transform="rotate(-180 90.6667 90)"
        fill="url(#paint1_linear_3838_41581)"
        fillOpacity="0.7"
      />
      <path
        d="M41.1667 90C41.1667 62.6619 63.3287 40.5 90.6667 40.5C118.005 40.5 140.167 62.6619 140.167 90C140.167 117.338 118.005 139.5 90.6667 139.5C63.3286 139.5 41.1667 117.338 41.1667 90Z"
        stroke="black"
      />
    </g>
    <path
      d="M91.9067 107.62C91.2267 107.86 90.1067 107.86 89.4267 107.62C83.6267 105.64 70.6667 97.3802 70.6667 83.3802C70.6667 77.2002 75.6467 72.2002 81.7867 72.2002C85.4267 72.2002 88.6467 73.9602 90.6667 76.6802C92.6867 73.9602 95.9267 72.2002 99.5467 72.2002C105.687 72.2002 110.667 77.2002 110.667 83.3802C110.667 97.3802 97.7067 105.64 91.9067 107.62Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <filter
        id="filter0_f_3838_41581"
        x="0.666748"
        y="0"
        width="180"
        height="180"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="20"
          result="effect1_foregroundBlur_3838_41581"
        />
      </filter>
      <linearGradient
        id="paint0_linear_3838_41581"
        x1="90.6667"
        y1="40"
        x2="90.6667"
        y2="140"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3838_41581"
        x1="45.0527"
        y1="90"
        x2="148.214"
        y2="91.1176"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF4566" />
        <stop offset="1" stopColor="#C645F4" />
      </linearGradient>
    </defs>
  </svg>
);
