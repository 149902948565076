import { Route } from 'react-justanother-router';

import { RouterName } from '@app/router';
import * as views from '@pages/Landing';

export const landingRoutes: Route[] = [
  {
    component: views.HomeView,
    name: RouterName.Home,
    path: '/',
  },
  {
    component: views.TeachersView,
    name: RouterName.Home,
    path: '/teachers',
  },
  {
    component: views.WikiView,
    name: RouterName.Wiki,
    path: '/wiki',
  },
];
