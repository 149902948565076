import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ArrowRightIcon, Button, Typography } from '@letsdance/ui-kit';
import { QUESTION_CARD } from '@widgets/Landing/const/main-text.const';
import { QuestionsIcon } from '@widgets/Landing/ui/icons/QuestionsIcon/QuestionsIcon';

import cls from './styles.module.scss';

export const Question: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <section className={cls.section}>
      <header className={cls.header}>
        <Typography
          variant={isDesktop ? 'head-60' : 'head-32'}
          color={'on-surface-primary-1'}>
          Кому подойдет H2Dance?
        </Typography>
        <Typography
          variant={'body-20'}
          color={'on-surface-primary-1'}
          className={cls.subtitle}>
          <b>H2dance</b> — платформа, которая прекрасно подходит для всех
          танцоров,
          <br /> от тех, кто никогда не танцевал, до тех, кто уже давно в теме
        </Typography>
      </header>
      <div className={cls.wrapper}>
        {QUESTION_CARD.map((item) => (
          <div key={item.title} className={cls.card}>
            <QuestionsIcon icon={item.icon} />
            <div className={cls.group}>
              <Typography variant={'head-32'} className={cls.title}>
                {item.title}
              </Typography>
              <ul className={cls.list}>
                {item.list.map((text) => (
                  <Typography
                    tag={'li'}
                    key={text}
                    variant={'body-20'}
                    color={'on-surface-primary-1'}>
                    {text}
                  </Typography>
                ))}
              </ul>
              {!isDesktop && (
                <Button
                  variant={'outlined'}
                  className={cls.btn}
                  onClick={() =>
                    window.open(
                      'https://t.me/h2dance_bot',
                      '_blank',
                      'noopener,noreferrer',
                    )
                  }>
                  Начать обучение
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
      {isDesktop && (
        <Typography
          variant={'body-20'}
          className={cls.text}
          onClick={() =>
            window.open(
              'https://t.me/h2dance_bot',
              '_blank',
              'noopener,noreferrer',
            )
          }>
          Начать обучение <ArrowRightIcon />
        </Typography>
      )}
    </section>
  );
};
