import {
  BaseTextsKeysEnum,
  ProductInput,
  ProductType,
  ProductTypeEnum,
  useBaseCourseTextsQuery,
} from '@/apolloGenerated';

export const getCourseInput = ({
  activeAcquiring,
  chanel_id,
  chat_id,
  description,
  faq,
  img,
  manualBuyText,
  name,
  period,
  preview: { active, back_button_text, description: descriptionPreview, url },
  query,
  successBuyText,
  tariffsUuid,
  type,
}: ProductInput) => ({
  activeAcquiring,
  chanel_id,
  chat_id,
  description,
  faq,
  img,
  manualBuyText,
  name,
  period,
  preview: {
    active,
    back_button_text,
    description: descriptionPreview,
    url,
  },
  query,
  successBuyText,
  tariffsUuid,
  type,
});
type CourseInitType = ProductInput & Pick<ProductType, 'tariffs'>;

export const useEventInitState = () => {
  const { data, loading } = useBaseCourseTextsQuery();

  const getByKey = (key: BaseTextsKeysEnum) =>
    data?.baseTexts.find((el) => el.key === key)?.text;

  return {
    getEventInitState: (): CourseInitType => ({
      activeAcquiring: true,
      chanel_id: 0,
      chat_id: null,
      description: '',
      faq: getByKey(BaseTextsKeysEnum.ProductFaqText),
      img: '',
      manualBuyText: getByKey(BaseTextsKeysEnum.ProductManualBuyText),
      name: '',
      period: null,
      preview: {
        active: false,
        back_button_text: null,
        description: null,
        url: null,
      },
      query: null,
      successBuyText: getByKey(BaseTextsKeysEnum.ProductSuccessBuyText),
      tariffs: [],
      tariffsUuid: [],
      type: ProductTypeEnum.Bachata,
    }),
    loading,
  };
};
