import React, { FC, useEffect } from 'react';

import { useSubmitManualOrderMutation } from '@/apolloGenerated';
import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface SubmitOrderModalProps {
  id: number;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    submitOrder: SubmitOrderModalProps;
  }
}
export const SubmitOrderModal: FC<WithCloseModal<SubmitOrderModalProps>> = ({
  id,
  onClose,
  onSuccess,
}): JSX.Element => {
  const [submitOrder, { client, data, error, loading }] =
    useSubmitManualOrderMutation();

  const handleSubmit = () => {
    submitOrder({ variables: { orderId: id } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: 'Покупка успешно подтверждена',
      });
      client.cache.evict({ fieldName: 'ordersList' });
      onSuccess && onSuccess();
    }
    if (error) {
      toaster.error({
        title: 'Ошибка подтверждения. Обратитесь за помощью в техподдержку',
      });
    }
    if (data || error) {
      onClose();
    }
  }, [data, error]);

  return (
    <Dialog
      title="Подтвердить получение средств за покупку"
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <div className="grid-2">
              <Button color="secondary" fullWidth onClick={onClose}>
                Отмена
              </Button>
              <Button fullWidth onClick={handleSubmit} disabled={loading}>
                Подтвердить
              </Button>
            </div>
          }
        />
      }
    />
  );
};
