import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends Record<string, unknown>, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;

/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: number; output: number };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian calendar.
   */
  DateTime: { input: any; output: any };
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian
   * calendar.This scalar is serialized to a string in ISO 8601 format and parsed
   * from a string in ISO 8601 format.
   */
  DateTimeISO: { input: number; output: number };
  /**
   * A field whose value conforms to the standard internet email address format as
   * specified in HTML Spec:
   * https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address.
   */
  EmailAddress: { input: any; output: any };
  /**
   * A field whose value conforms to the standard E.164 format as specified in:
   * https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234.
   */
  PhoneNumber: { input: any; output: any };
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: any; output: any };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any };
  /** Represents NULL values */
  Void: { input: any; output: any };
}

export type BanOrderType = NodeIdType & {
  __typename?: 'BanOrderType';
  channel_id: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  tgId: Scalars['Float']['output'];
};

export interface BaseGraphModel {
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
}

export enum BaseTextsKeysEnum {
  ProductFaqText = 'ProductFaqText',
  ProductManualBuyText = 'ProductManualBuyText',
  ProductSuccessBuyText = 'ProductSuccessBuyText',
  TeacherProductListText = 'TeacherProductListText',
  TeacherProductSocialsText = 'TeacherProductSocialsText',
}

export interface BaseTextsType {
  __typename?: 'BaseTextsType';
  key: BaseTextsKeysEnum;
  text: Scalars['String']['output'];
}

export interface CreateAdminUserInput {
  password: Scalars['String']['input'];
  teacherId: Scalars['Int']['input'];
  username: Scalars['String']['input'];
}

export interface CreateInvoiceInput {
  uuidProduct: Scalars['UUID']['input'];
  uuidTariff: Scalars['UUID']['input'];
}

export interface CreateOfflineCourseInput {
  description: Scalars['String']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
  name: Scalars['String']['input'];
}

export interface ErrorType {
  __typename?: 'ErrorType';
  errors: Errors;
}

export enum Errors {
  AcquiringTurnOff = 'AcquiringTurnOff',
  AcquiringTurnOn = 'AcquiringTurnOn',
  AlreadyExecuted = 'AlreadyExecuted',
  AlreadyPaid = 'AlreadyPaid',
  CanActivateOnlyDraftItem = 'CanActivateOnlyDraftItem',
  CanArchiveOnlyActiveItem = 'CanArchiveOnlyActiveItem',
  CanDeletedOnlyDraftProduct = 'CanDeletedOnlyDraftProduct',
  CanNotChangeUserInfo = 'CanNotChangeUserInfo',
  CanNotClearCurrentUser = 'CanNotClearCurrentUser',
  CanNotDeactivateNotTeacherUser = 'CanNotDeactivateNotTeacherUser',
  CanNotEditAdminUser = 'CanNotEditAdminUser',
  CanNotPublishProductWithoutTariff = 'CanNotPublishProductWithoutTariff',
  CanRemoveOnlyDraftItem = 'CanRemoveOnlyDraftItem',
  CouldNotRefreshToken = 'CouldNotRefreshToken',
  EmailAlreadyConfirmed = 'EmailAlreadyConfirmed',
  FailedConfirmEmail = 'FailedConfirmEmail',
  FailedGetPayment = 'FailedGetPayment',
  FailedInitPayment = 'FailedInitPayment',
  FailedRemovePaymentAccount = 'FailedRemovePaymentAccount',
  FailedSendMail = 'FailedSendMail',
  FailedUploadFile = 'FailedUploadFile',
  InsufficientFundsOnBalance = 'InsufficientFundsOnBalance',
  InvalidAccessToken = 'InvalidAccessToken',
  InvalidAuthData = 'InvalidAuthData',
  InvalidCode = 'InvalidCode',
  InvalidFileType = 'InvalidFileType',
  NotFound = 'NotFound',
  NotFoundAccessToken = 'NotFoundAccessToken',
  NotManualPayOrder = 'NotManualPayOrder',
  NotPassedFullSupplierInfo = 'NotPassedFullSupplierInfo',
  NotSendCode = 'NotSendCode',
  PaymentAccountNotFound = 'PaymentAccountNotFound',
  PermissionDenied = 'PermissionDenied',
  ProductAlreadyHaveThisStatus = 'ProductAlreadyHaveThisStatus',
  ProductIsArchived = 'ProductIsArchived',
  ProductMustHaveLeastOneTariff = 'ProductMustHaveLeastOneTariff',
  ProductNotFound = 'ProductNotFound',
  TariffNotFound = 'TariffNotFound',
  TeacherNotFound = 'TeacherNotFound',
  UserNotFound = 'UserNotFound',
  UserWithThisEmailAlreadyExist = 'UserWithThisEmailAlreadyExist',
  UserWithThisTokenNotFound = 'UserWithThisTokenNotFound',
}

export interface FileType {
  __typename?: 'FileType';
  url: Scalars['String']['output'];
}

export interface FilterUsersInput {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface FinanceStatsType {
  __typename?: 'FinanceStatsType';
  profit: Scalars['Float']['output'];
  totalCommission: Scalars['Float']['output'];
  totalReceived: Scalars['Float']['output'];
  totalWithdrawals: Scalars['Float']['output'];
}

export enum GenderEnum {
  Female = 'Female',
  Male = 'Male',
}

export type InvoiceManualType = NodeIdType & {
  __typename?: 'InvoiceManualType';
  id: Scalars['ID']['output'];
  status: OrderStatusEnum;
};

export type InvoiceType = NodeIdType & {
  __typename?: 'InvoiceType';
  id: Scalars['ID']['output'];
  invoice_link: Scalars['String']['output'];
  qr_link?: Maybe<Scalars['String']['output']>;
  status: OrderStatusEnum;
};

export interface JwtType {
  __typename?: 'JwtType';
  access_token: Scalars['String']['output'];
  refresh_token: Scalars['String']['output'];
}

export enum LanguageEnum {
  English = 'English',
  Russian = 'Russian',
}

export interface LoginInput {
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
}

export interface LoginTgInput {
  password: Scalars['String']['input'];
  tgId: Scalars['Float']['input'];
}

export interface Mutation {
  __typename?: 'Mutation';
  activateProduct: Scalars['Void']['output'];
  addPaymentAccount: PaymentAccountType;
  addUserTeacher: Scalars['Void']['output'];
  archiveProduct: Scalars['Void']['output'];
  cancelInvoice?: Maybe<OrderType>;
  cancelManualOrder: OrderType;
  cancelWithdrawal: Scalars['Void']['output'];
  changeLanguage: Scalars['Void']['output'];
  clearUserByTg: Scalars['Void']['output'];
  completeOrder?: Maybe<OrderType>;
  confirmEmail: Scalars['Void']['output'];
  createAdminUser: UserType;
  createInvoice: InvoiceType;
  createManualInvoice: InvoiceManualType;
  createOfflineCourse: OfflineCourseType;
  createProduct: ProductType;
  createTariff: TariffType;
  createTeacher: TeacherType;
  deactivateUserTeacher?: Maybe<UserType>;
  login: JwtType;
  loginTg: JwtType;
  logout: Scalars['Void']['output'];
  refresh: JwtType;
  removePaymentAccount: Scalars['Void']['output'];
  removeProduct: Scalars['Void']['output'];
  requestWithdrawal: Scalars['Void']['output'];
  saveInvoiceProofs: Scalars['Void']['output'];
  saveUserInfo: UserType;
  signUp: UserType;
  submitManualOrder: OrderType;
  updatePaymentAccount?: Maybe<PaymentAccountType>;
  updateProduct: ProductType;
  updateTariff: TariffType;
  updateTeacher: TeacherType;
  updateUser?: Maybe<UserType>;
  uploadFile: FileType;
  uploadImages: FileType[];
  verifyEmail: UserType;
  verifyEmailLater: UserType;
}

export interface MutationActivateProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationAddPaymentAccountArgs {
  idTeacher: Scalars['Int']['input'];
  input: PaymentAccountInput;
}

export interface MutationAddUserTeacherArgs {
  idTeacher: Scalars['Int']['input'];
}

export interface MutationArchiveProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationCancelInvoiceArgs {
  id: Scalars['Int']['input'];
}

export interface MutationCancelManualOrderArgs {
  orderId: Scalars['Float']['input'];
}

export interface MutationCancelWithdrawalArgs {
  idTeacher: Scalars['Int']['input'];
  uuid: Scalars['UUID']['input'];
}

export interface MutationChangeLanguageArgs {
  language: LanguageEnum;
}

export interface MutationClearUserByTgArgs {
  tgId: Scalars['Float']['input'];
}

export interface MutationCompleteOrderArgs {
  id: Scalars['Int']['input'];
}

export interface MutationConfirmEmailArgs {
  email: Scalars['EmailAddress']['input'];
}

export interface MutationCreateAdminUserArgs {
  input: CreateAdminUserInput;
}

export interface MutationCreateInvoiceArgs {
  input: CreateInvoiceInput;
}

export interface MutationCreateManualInvoiceArgs {
  input: CreateInvoiceInput;
}

export interface MutationCreateOfflineCourseArgs {
  idTeacher: Scalars['Int']['input'];
  input: CreateOfflineCourseInput;
}

export interface MutationCreateProductArgs {
  idTeacher: Scalars['Float']['input'];
  input: ProductInput;
  publish?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface MutationCreateTariffArgs {
  input: TariffInput;
}

export interface MutationCreateTeacherArgs {
  input: TeacherInput;
}

export interface MutationDeactivateUserTeacherArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationLoginArgs {
  input: LoginInput;
}

export interface MutationLoginTgArgs {
  input: LoginTgInput;
}

export interface MutationRefreshArgs {
  refresh_token: Scalars['String']['input'];
}

export interface MutationRemovePaymentAccountArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationRemoveProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationRequestWithdrawalArgs {
  input: WithdrawalTeacherInput;
}

export interface MutationSaveInvoiceProofsArgs {
  image: Scalars['Upload']['input'];
  orderId: Scalars['Float']['input'];
}

export interface MutationSaveUserInfoArgs {
  input: SaveUserInfoInput;
}

export interface MutationSignUpArgs {
  input: SignUpInput;
}

export interface MutationSubmitManualOrderArgs {
  orderId: Scalars['Float']['input'];
}

export interface MutationUpdatePaymentAccountArgs {
  input: PaymentAccountInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateProductArgs {
  input: ProductInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateTariffArgs {
  input: TariffInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateTeacherArgs {
  id: Scalars['Float']['input'];
  input: TeacherInput;
}

export interface MutationUpdateUserArgs {
  input: UpdateUserInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUploadFileArgs {
  file: Scalars['Upload']['input'];
}

export interface MutationUploadImagesArgs {
  image: Scalars['Upload']['input'][];
}

export interface MutationVerifyEmailArgs {
  code: Scalars['String']['input'];
}

export interface MutationVerifyEmailLaterArgs {
  email: Scalars['EmailAddress']['input'];
}

export interface NodeIdType {
  id: Scalars['ID']['output'];
}

export interface NodeUuidType {
  uuid: Scalars['UUID']['output'];
}

export type OfflineCourseType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'OfflineCourseType';
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description: Scalars['String']['output'];
    image?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
  };

export interface OfflineCoursesResponse {
  __typename?: 'OfflineCoursesResponse';
  count: Scalars['Int']['output'];
  rows: OfflineCourseType[];
}

export type OrderProofType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'OrderProofType';
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    file: Scalars['String']['output'];
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
  };

export enum OrderSortKeys {
  Completed = 'completed',
  CreatedAt = 'createdAt',
  ExpiredDate = 'expired_date',
  Id = 'id',
  Price = 'price',
  Status = 'status',
}

export interface OrderSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: OrderSortKeys;
}

export enum OrderStatusEnum {
  Canceled = 'Canceled',
  Created = 'Created',
  Failed = 'Failed',
  Overdue = 'Overdue',
  Pending = 'Pending',
  Success = 'Success',
}

export type OrderType = BaseGraphModel &
  NodeIdType & {
    __typename?: 'OrderType';
    completed: Scalars['Boolean']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    expired_date?: Maybe<Scalars['DateTime']['output']>;
    id: Scalars['ID']['output'];
    invoice_url?: Maybe<Scalars['String']['output']>;
    price: Scalars['Float']['output'];
    product: ProductType;
    proof?: Maybe<OrderProofType>;
    status: OrderStatusEnum;
    tariff: TariffType;
    teacher: TeacherType;
    teacherCommission: Scalars['Float']['output'];
    updatedAt: Scalars['DateTimeISO']['output'];
    user: UserType;
  };

export enum OrderTypeEnum {
  AcquiringPay = 'AcquiringPay',
  ManualPay = 'ManualPay',
}

export interface OrdersCountsType {
  __typename?: 'OrdersCountsType';
  active: Scalars['Float']['output'];
  all: Scalars['Float']['output'];
  expired: Scalars['Float']['output'];
}

export interface OrdersFilterInput {
  teacherId?: InputMaybe<Scalars['Int']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
}

export interface OrdersListFilterInput {
  order?: InputMaybe<OrderSortType>;
  pagination?: InputMaybe<PaginationType>;
}

export interface OrdersParamsInput {
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<OrderStatusEnum>;
  type?: InputMaybe<OrderTypeEnum>;
}

export interface OrdersResponse {
  __typename?: 'OrdersResponse';
  count: Scalars['Int']['output'];
  rows: OrderType[];
}

export interface OrdersStatsFilterInput {
  teacherId?: InputMaybe<Scalars['Int']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}

export interface OrdersStatsType {
  __typename?: 'OrdersStatsType';
  financeStats: FinanceStatsType;
  ordersCounts: OrdersCountsType;
  usersCounts: UsersCountsType;
}

export interface PaginationType {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}

export interface ParamsUsersInput {
  teacherId?: InputMaybe<Scalars['Int']['input']>;
}

export interface PaymentAccountInput {
  accountNumber: Scalars['String']['input'];
  bik: Scalars['String']['input'];
  inn: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export type PaymentAccountType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'PaymentAccountType';
    accountNumber: Scalars['String']['output'];
    bik: Scalars['String']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    inn: Scalars['String']['output'];
    name: Scalars['String']['output'];
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
  };

export interface PlatformProfitStatsType {
  __typename?: 'PlatformProfitStatsType';
  profit: Scalars['Float']['output'];
}

export interface PreviewProductInput {
  active: Scalars['Boolean']['input'];
  back_button_text?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
}

export interface PreviewProductType {
  __typename?: 'PreviewProductType';
  active: Scalars['Boolean']['output'];
  back_button_text?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface ProductCountsType {
  __typename?: 'ProductCountsType';
  activeCount: Scalars['Float']['output'];
  archiveCount: Scalars['Float']['output'];
  count: Scalars['Float']['output'];
  draftCount: Scalars['Float']['output'];
}

export interface ProductFilter {
  teacherId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProductInput {
  activeAcquiring: Scalars['Boolean']['input'];
  chanel_id: Scalars['Float']['input'];
  chat_id?: InputMaybe<Scalars['Float']['input']>;
  description: Scalars['String']['input'];
  faq?: InputMaybe<Scalars['String']['input']>;
  img: Scalars['String']['input'];
  manualBuyText?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  period?: InputMaybe<Scalars['String']['input']>;
  preview: PreviewProductInput;
  query?: InputMaybe<Scalars['String']['input']>;
  successBuyText?: InputMaybe<Scalars['String']['input']>;
  tariffsUuid: Scalars['UUID']['input'][];
  type: ProductTypeEnum;
}

export interface ProductListFilterInput {
  order?: InputMaybe<ProductSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<ProductSearchType>;
}

export enum ProductSearchKeysEnum {
  Description = 'description',
  Name = 'name',
  Type = 'type',
}

export interface ProductSearchType {
  search: Scalars['String']['input'];
  searchBy: ProductSearchKeysEnum;
}

export enum ProductSortKeys {
  CreatedAt = 'createdAt',
  Name = 'name',
  Status = 'status',
  Type = 'type',
}

export interface ProductSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: ProductSortKeys;
}

export type ProductType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'ProductType';
    activeAcquiring: Scalars['Boolean']['output'];
    chanel_id: Scalars['Float']['output'];
    chat_id?: Maybe<Scalars['Float']['output']>;
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description: Scalars['String']['output'];
    faq: Scalars['String']['output'];
    haveAccessCount: Scalars['Float']['output'];
    img: Scalars['String']['output'];
    manualBuyText: Scalars['String']['output'];
    name: Scalars['String']['output'];
    period?: Maybe<Scalars['String']['output']>;
    preview: PreviewProductType;
    purchasedCount: Scalars['Float']['output'];
    query?: Maybe<Scalars['String']['output']>;
    receipts: Scalars['Float']['output'];
    status: StatusEnum;
    successBuyText: Scalars['String']['output'];
    tariffs: TariffType[];
    teacher: TeacherType;
    type: ProductTypeEnum;
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
  };

export interface ProductTypeFaqArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeManualBuyTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeSuccessBuyTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export enum ProductTypeEnum {
  Bachata = 'Bachata',
  Hastl = 'Hastl',
  Kizomba = 'Kizomba',
  Mambo = 'Mambo',
  Oriental = 'Oriental',
  Other = 'Other',
  Salsa = 'Salsa',
  Tango = 'Tango',
}

export interface ProductsResponse {
  __typename?: 'ProductsResponse';
  count: Scalars['Int']['output'];
  rows: ProductType[];
}

export interface Query {
  __typename?: 'Query';
  baseTexts: BaseTextsType[];
  me: UserType;
  offlineCourse: OfflineCourseType;
  offlineCourses: OfflineCoursesResponse;
  order: OrderType;
  orderByProductTariff: OrderType;
  /** Getting a list of transactions for the user */
  orders: OrdersResponse;
  ordersForBan: BanOrderType[];
  /** Getting a list of transactions for the admin */
  ordersList: OrdersResponse;
  ordersStats: OrdersStatsType;
  paymentsByTeacher: PaymentAccountType[];
  platformProfitStats: PlatformProfitStatsType;
  product: ProductType;
  productByQuery: ProductType;
  productShortcodes: Scalars['String']['output'][];
  products: ProductsResponse;
  productsCount: ProductCountsType;
  productsList: ProductsResponse;
  tariff: TariffType;
  tariffs: TariffsResponse;
  teacher: TeacherType;
  teacherByTg: TeacherType;
  teacherShortcodes: Scalars['String']['output'][];
  teachers: TeachersResponse;
  teachersList: TeachersResponse;
  user: UserType;
  users: UsersResponse;
  usersControlTeacher: UsersResponse;
  usersStats: UsersStatsType;
  withdrawalsByTeacher: WithdrawalsResponse;
}

export interface QueryBaseTextsArgs {
  keys: BaseTextsKeysEnum[];
}

export interface QueryOfflineCourseArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryOfflineCoursesArgs {
  pagination?: InputMaybe<PaginationType>;
}

export interface QueryOrderArgs {
  id: Scalars['Int']['input'];
}

export interface QueryOrderByProductTariffArgs {
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<OrderStatusEnum>;
  uuidProduct: Scalars['UUID']['input'];
  uuidTariff: Scalars['UUID']['input'];
}

export interface QueryOrdersArgs {
  filters?: InputMaybe<OrdersListFilterInput>;
  params?: InputMaybe<OrdersParamsInput>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}

export interface QueryOrdersListArgs {
  args?: InputMaybe<OrdersFilterInput>;
  filters?: InputMaybe<OrdersListFilterInput>;
  params?: InputMaybe<OrdersParamsInput>;
}

export interface QueryOrdersStatsArgs {
  args?: InputMaybe<OrdersStatsFilterInput>;
}

export interface QueryPaymentsByTeacherArgs {
  idTeacher: Scalars['Int']['input'];
}

export interface QueryPlatformProfitStatsArgs {
  teacherId?: InputMaybe<Scalars['Int']['input']>;
}

export interface QueryProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryProductByQueryArgs {
  query: Scalars['String']['input'];
}

export interface QueryProductsArgs {
  filters?: InputMaybe<ProductListFilterInput>;
  params?: InputMaybe<ProductFilter>;
}

export interface QueryProductsCountArgs {
  teacherId?: InputMaybe<Scalars['Float']['input']>;
}

export interface QueryProductsListArgs {
  filters?: InputMaybe<ProductListFilterInput>;
  params?: InputMaybe<ProductFilter>;
  status?: InputMaybe<StatusEnum[]>;
}

export interface QueryTariffArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryTariffsArgs {
  filters?: InputMaybe<TariffListFilterInput>;
  params?: InputMaybe<TariffParams>;
}

export interface QueryTeacherArgs {
  id: Scalars['Int']['input'];
}

export interface QueryTeacherByTgArgs {
  tg: Scalars['String']['input'];
}

export interface QueryTeachersArgs {
  filters?: InputMaybe<TeacherListFilterInput>;
}

export interface QueryTeachersListArgs {
  filters?: InputMaybe<TeacherListFilterInput>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface QueryUserArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryUsersArgs {
  args?: InputMaybe<FilterUsersInput>;
  filters?: InputMaybe<UserListFilterInput>;
  params?: InputMaybe<ParamsUsersInput>;
}

export interface QueryUsersControlTeacherArgs {
  filters?: InputMaybe<UserControlTeacherListFilterInput>;
  teacherId: Scalars['Float']['input'];
}

export interface QueryUsersStatsArgs {
  args?: InputMaybe<FilterUsersInput>;
  params?: InputMaybe<ParamsUsersInput>;
}

export interface QueryWithdrawalsByTeacherArgs {
  filters?: InputMaybe<WithdrawalListFilterInput>;
  idTeacher: Scalars['Int']['input'];
}

export enum RoleEnum {
  Admin = 'Admin',
  Marketer = 'Marketer',
  Teacher = 'Teacher',
  User = 'User',
}

export interface SaveUserInfoInput {
  first_name: Scalars['String']['input'];
  gender: GenderEnum;
  last_name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
}

export interface SignUpInput {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  gender?: InputMaybe<GenderEnum>;
  language?: LanguageEnum;
  last_name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  tgId: Scalars['Float']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
}

export enum SortEnum {
  Asc = 'Asc',
  Desc = 'Desc',
}

export enum StatusEnum {
  Active = 'Active',
  Archive = 'Archive',
  Draft = 'Draft',
}

export interface TariffInput {
  chanel_id?: InputMaybe<Scalars['Float']['input']>;
  chat_id?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  special_price?: InputMaybe<Scalars['Float']['input']>;
}

export interface TariffListFilterInput {
  order?: InputMaybe<TariffSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<TariffSearchType>;
}

export interface TariffParams {
  productUuid: Scalars['UUID']['input'];
}

export enum TariffSearchKeysEnum {
  Description = 'description',
  Name = 'name',
}

export interface TariffSearchType {
  search: Scalars['String']['input'];
  searchBy: TariffSearchKeysEnum;
}

export enum TariffSortKeysEnum {
  CreatedAt = 'createdAt',
  Name = 'name',
  Price = 'price',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

export interface TariffSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: TariffSortKeysEnum;
}

export type TariffType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'TariffType';
    chanel_id?: Maybe<Scalars['Float']['output']>;
    chat_id?: Maybe<Scalars['Float']['output']>;
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description: Scalars['String']['output'];
    name: Scalars['String']['output'];
    period?: Maybe<Scalars['String']['output']>;
    price: Scalars['Float']['output'];
    special_price?: Maybe<Scalars['Float']['output']>;
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
  };

export interface TariffsResponse {
  __typename?: 'TariffsResponse';
  count: Scalars['Int']['output'];
  rows: TariffType[];
}

export interface TeacherFilesType {
  __typename?: 'TeacherFilesType';
  agent?: Maybe<Scalars['String']['output']>;
  oferta?: Maybe<Scalars['String']['output']>;
  policy?: Maybe<Scalars['String']['output']>;
}

export interface TeacherInput {
  description: Scalars['String']['input'];
  facebook?: InputMaybe<Scalars['String']['input']>;
  image: Scalars['String']['input'];
  instagram?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  productsListText?: InputMaybe<Scalars['String']['input']>;
  socialImage?: InputMaybe<Scalars['String']['input']>;
  socialsText?: InputMaybe<Scalars['String']['input']>;
  supplierInfo: TeacherSupplierInfoInput;
  telegram: Scalars['String']['input'];
  telegramSocial?: InputMaybe<Scalars['String']['input']>;
  vk?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface TeacherListFilterInput {
  order?: InputMaybe<TeacherSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<TeacherSearchType>;
}

export enum TeacherSearchKeys {
  Description = 'description',
  Name = 'name',
  Telegram = 'telegram',
}

export interface TeacherSearchType {
  search: Scalars['String']['input'];
  searchBy: TeacherSearchKeys;
}

export enum TeacherSortKeys {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Telegram = 'telegram',
}

export interface TeacherSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: TeacherSortKeys;
}

export interface TeacherSupplierInfoInput {
  inn?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
}

export interface TeacherSupplierInfoType {
  __typename?: 'TeacherSupplierInfoType';
  inn?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
}

export type TeacherType = BaseGraphModel &
  NodeIdType & {
    __typename?: 'TeacherType';
    balance: Scalars['Float']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description: Scalars['String']['output'];
    facebook?: Maybe<Scalars['String']['output']>;
    files: TeacherFilesType;
    id: Scalars['ID']['output'];
    image: Scalars['String']['output'];
    instagram?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    offlineCourseCount: Scalars['Int']['output'];
    offlineCourses: OfflineCourseType[];
    ordersCount: Scalars['Int']['output'];
    paymentAccounts: PaymentAccountType[];
    products: ProductType[];
    productsCount: Scalars['Int']['output'];
    productsListText: Scalars['String']['output'];
    receipts: Scalars['Float']['output'];
    socialImage?: Maybe<Scalars['String']['output']>;
    socialsText: Scalars['String']['output'];
    supplierInfo: TeacherSupplierInfoType;
    telegram: Scalars['String']['output'];
    telegramSocial?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['DateTimeISO']['output'];
    vk?: Maybe<Scalars['String']['output']>;
    withdrawals: WithdrawalType[];
    youtube?: Maybe<Scalars['String']['output']>;
  };

export interface TeacherTypeProductsListTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface TeacherTypeSocialsTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface TeachersResponse {
  __typename?: 'TeachersResponse';
  count: Scalars['Int']['output'];
  rows: TeacherType[];
}

export interface UpdateUserInput {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  gender?: InputMaybe<GenderEnum>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
}

export interface UserControlTeacherListFilterInput {
  order?: InputMaybe<UserSortType>;
  pagination?: InputMaybe<PaginationType>;
}

export interface UserListFilterInput {
  order?: InputMaybe<UserSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export enum UserSortKeys {
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'first_name',
  Gender = 'gender',
  LastName = 'last_name',
  Phone = 'phone',
  TgId = 'tgId',
  Username = 'username',
}

export interface UserSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: UserSortKeys;
}

export type UserType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'UserType';
    controlTeacherId?: Maybe<Scalars['Int']['output']>;
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    email?: Maybe<Scalars['EmailAddress']['output']>;
    facebook?: Maybe<Scalars['String']['output']>;
    first_name: Scalars['String']['output'];
    gender?: Maybe<GenderEnum>;
    haveAccessCount: Scalars['Int']['output'];
    instagram?: Maybe<Scalars['String']['output']>;
    language: LanguageEnum;
    last_name?: Maybe<Scalars['String']['output']>;
    ordersCount: Scalars['Int']['output'];
    phone?: Maybe<Scalars['PhoneNumber']['output']>;
    receipts: Scalars['Float']['output'];
    role: RoleEnum;
    teachers: TeacherType[];
    tgId?: Maybe<Scalars['Float']['output']>;
    updatedAt: Scalars['DateTimeISO']['output'];
    username?: Maybe<Scalars['String']['output']>;
    uuid: Scalars['UUID']['output'];
    vk?: Maybe<Scalars['String']['output']>;
  };

export interface UsersCountsType {
  __typename?: 'UsersCountsType';
  all: Scalars['Float']['output'];
  female: Scalars['Float']['output'];
  male: Scalars['Float']['output'];
}

export interface UsersResponse {
  __typename?: 'UsersResponse';
  count: Scalars['Int']['output'];
  rows: UserType[];
}

export interface UsersStatsType {
  __typename?: 'UsersStatsType';
  count: Scalars['Int']['output'];
  femaleCount: Scalars['Int']['output'];
  maleCount: Scalars['Int']['output'];
}

export interface WithdrawalListFilterInput {
  order?: InputMaybe<WithdrawalSortType>;
  pagination?: InputMaybe<PaginationType>;
}

export enum WithdrawalSortKeys {
  Amount = 'amount',
  CreatedAt = 'createdAt',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

export interface WithdrawalSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: WithdrawalSortKeys;
}

export enum WithdrawalStatusEnum {
  Cancelled = 'Cancelled',
  Executed = 'Executed',
  Failed = 'Failed',
  InProgress = 'InProgress',
}

export interface WithdrawalTeacherInput {
  amount: Scalars['Float']['input'];
  teacherId: Scalars['Int']['input'];
  uuidPaymentAccount: Scalars['UUID']['input'];
}

export type WithdrawalType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'WithdrawalType';
    amount: Scalars['Float']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    paymentAccount: PaymentAccountType;
    status: WithdrawalStatusEnum;
    teacher: TeacherType;
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
  };

export interface WithdrawalsResponse {
  __typename?: 'WithdrawalsResponse';
  count: Scalars['Int']['output'];
  rows: WithdrawalType[];
}

export interface JwtFieldsFragment {
  __typename?: 'JwtType';
  access_token: string;
  refresh_token: string;
}

export type LoginMutationVariables = Exact<{
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export interface LoginMutation {
  __typename?: 'Mutation';
  login: {
    __typename?: 'JwtType';
    access_token: string;
    refresh_token: string;
  };
}

export type RefreshMutationVariables = Exact<{
  refresh_token: Scalars['String']['input'];
}>;

export interface RefreshMutation {
  __typename?: 'Mutation';
  refresh: {
    __typename?: 'JwtType';
    access_token: string;
    refresh_token: string;
  };
}

export type MeQueryVariables = Exact<Record<string, never>>;

export interface MeQuery {
  __typename?: 'Query';
  me: {
    __typename?: 'UserType';
    uuid: any;
    username?: string | null;
    first_name: string;
    last_name?: string | null;
    role: RoleEnum;
    email?: any | null;
    phone?: any | null;
    controlTeacherId?: number | null;
  };
}

export type UploadImagesMutationVariables = Exact<{
  images: Scalars['Upload']['input'][] | Scalars['Upload']['input'];
}>;

export interface UploadImagesMutation {
  __typename?: 'Mutation';
  uploadImages: { __typename?: 'FileType'; url: string }[];
}

export type FinanceStatsQueryVariables = Exact<{
  teacherId: Scalars['Int']['input'];
}>;

export interface FinanceStatsQuery {
  __typename?: 'Query';
  ordersStats: {
    __typename?: 'OrdersStatsType';
    ordersCounts: { __typename?: 'OrdersCountsType'; all: number };
    financeStats: {
      __typename?: 'FinanceStatsType';
      profit: number;
      totalCommission: number;
      totalReceived: number;
      totalWithdrawals: number;
    };
  };
  teacher: { __typename?: 'TeacherType'; balance: number };
}

export type FinanceListsCountQueryVariables = Exact<{
  teacherId: Scalars['Int']['input'];
}>;

export interface FinanceListsCountQuery {
  __typename?: 'Query';
  ordersList: { __typename?: 'OrdersResponse'; count: number };
  withdrawalsByTeacher: { __typename?: 'WithdrawalsResponse'; count: number };
}

export type PurchaseStatsQueryVariables = Exact<{
  teacherId: Scalars['Int']['input'];
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}>;

export interface PurchaseStatsQuery {
  __typename?: 'Query';
  ordersStats: {
    __typename?: 'OrdersStatsType';
    usersCounts: {
      __typename?: 'UsersCountsType';
      all: number;
      female: number;
      male: number;
    };
    financeStats: { __typename?: 'FinanceStatsType'; profit: number };
  };
}

export interface OrderFieldsFragment {
  __typename?: 'OrderType';
  createdAt: number;
  updatedAt: number;
  deletedAt?: number | null;
  id: number;
  completed: boolean;
  expired_date?: any | null;
  price: number;
  teacherCommission: number;
  status: OrderStatusEnum;
  invoice_url?: string | null;
}

export type OrdersListQueryVariables = Exact<{
  filter?: InputMaybe<OrdersListFilterInput>;
  teacherId: Scalars['Int']['input'];
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
  isExpired?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export interface OrdersListQuery {
  __typename?: 'Query';
  ordersList: {
    __typename?: 'OrdersResponse';
    count: number;
    rows: {
      __typename?: 'OrderType';
      createdAt: number;
      updatedAt: number;
      deletedAt?: number | null;
      id: number;
      completed: boolean;
      expired_date?: any | null;
      price: number;
      teacherCommission: number;
      status: OrderStatusEnum;
      invoice_url?: string | null;
      user: {
        __typename?: 'UserType';
        first_name: string;
        last_name?: string | null;
        email?: any | null;
        username?: string | null;
        uuid: any;
        phone?: any | null;
      };
      product: {
        __typename?: 'ProductType';
        name: string;
        type: ProductTypeEnum;
      };
      tariff: { __typename?: 'TariffType'; name: string };
    }[];
  };
}

export type OrdersCountQueryVariables = Exact<{
  filter?: InputMaybe<OrdersListFilterInput>;
  teacherId: Scalars['Int']['input'];
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
  isExpired?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export interface OrdersCountQuery {
  __typename?: 'Query';
  ordersList: { __typename?: 'OrdersResponse'; count: number };
}

export type OrdersAwaitSubmitListQueryVariables = Exact<{
  filter?: InputMaybe<OrdersListFilterInput>;
  teacherId: Scalars['Int']['input'];
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}>;

export interface OrdersAwaitSubmitListQuery {
  __typename?: 'Query';
  ordersList: {
    __typename?: 'OrdersResponse';
    count: number;
    rows: {
      __typename?: 'OrderType';
      createdAt: number;
      updatedAt: number;
      deletedAt?: number | null;
      id: number;
      completed: boolean;
      expired_date?: any | null;
      price: number;
      teacherCommission: number;
      status: OrderStatusEnum;
      invoice_url?: string | null;
      proof?: { __typename?: 'OrderProofType'; file: string } | null;
      user: {
        __typename?: 'UserType';
        first_name: string;
        last_name?: string | null;
        email?: any | null;
        username?: string | null;
        uuid: any;
        phone?: any | null;
      };
      product: {
        __typename?: 'ProductType';
        name: string;
        type: ProductTypeEnum;
      };
      tariff: { __typename?: 'TariffType'; name: string };
    }[];
  };
}

export type OrdersAwaitSubmitCountQueryVariables = Exact<{
  teacherId: Scalars['Int']['input'];
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}>;

export interface OrdersAwaitSubmitCountQuery {
  __typename?: 'Query';
  ordersList: { __typename?: 'OrdersResponse'; count: number };
}

export type SubmitManualOrderMutationVariables = Exact<{
  orderId: Scalars['Float']['input'];
}>;

export interface SubmitManualOrderMutation {
  __typename?: 'Mutation';
  submitManualOrder: { __typename?: 'OrderType'; id: number };
}

export type CancelManualOrderMutationVariables = Exact<{
  orderId: Scalars['Float']['input'];
}>;

export interface CancelManualOrderMutation {
  __typename?: 'Mutation';
  cancelManualOrder: { __typename?: 'OrderType'; id: number };
}

export interface PaymentAccountFieldsFragment {
  __typename?: 'PaymentAccountType';
  createdAt: number;
  updatedAt: number;
  deletedAt?: number | null;
  uuid: any;
  name: string;
  inn: string;
  bik: string;
  accountNumber: string;
}

export type PaymentAccountsListQueryVariables = Exact<{
  teacherId: Scalars['Int']['input'];
}>;

export interface PaymentAccountsListQuery {
  __typename?: 'Query';
  paymentsByTeacher: {
    __typename?: 'PaymentAccountType';
    createdAt: number;
    updatedAt: number;
    deletedAt?: number | null;
    uuid: any;
    name: string;
    inn: string;
    bik: string;
    accountNumber: string;
  }[];
}

export interface ProductPreviewFieldsFragment {
  __typename?: 'PreviewProductType';
  active: boolean;
  url?: string | null;
  description?: string | null;
  back_button_text?: string | null;
}

export interface ProductFieldsFragment {
  __typename?: 'ProductType';
  createdAt: number;
  updatedAt: number;
  deletedAt?: number | null;
  uuid: any;
  img: string;
  name: string;
  description: string;
  successBuyText: string;
  manualBuyText: string;
  activeAcquiring: boolean;
  faq: string;
  period?: string | null;
  chanel_id: number;
  query?: string | null;
  chat_id?: number | null;
  type: ProductTypeEnum;
  purchasedCount: number;
  receipts: number;
  haveAccessCount: number;
  status: StatusEnum;
  preview: {
    __typename?: 'PreviewProductType';
    active: boolean;
    url?: string | null;
    description?: string | null;
    back_button_text?: string | null;
  };
}

export type BaseCourseTextsQueryVariables = Exact<Record<string, never>>;

export interface BaseCourseTextsQuery {
  __typename?: 'Query';
  baseTexts: {
    __typename?: 'BaseTextsType';
    key: BaseTextsKeysEnum;
    text: string;
  }[];
}

export type CoursesCountQueryVariables = Exact<{
  teacherId?: InputMaybe<Scalars['Float']['input']>;
}>;

export interface CoursesCountQuery {
  __typename?: 'Query';
  productsCount: {
    __typename?: 'ProductCountsType';
    count: number;
    activeCount: number;
    archiveCount: number;
    draftCount: number;
  };
}

export type CourseQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface CourseQuery {
  __typename?: 'Query';
  product: {
    __typename?: 'ProductType';
    createdAt: number;
    updatedAt: number;
    deletedAt?: number | null;
    uuid: any;
    img: string;
    name: string;
    description: string;
    successBuyText: string;
    manualBuyText: string;
    activeAcquiring: boolean;
    faq: string;
    period?: string | null;
    chanel_id: number;
    query?: string | null;
    chat_id?: number | null;
    type: ProductTypeEnum;
    purchasedCount: number;
    receipts: number;
    haveAccessCount: number;
    status: StatusEnum;
    tariffs: { __typename?: 'TariffType'; uuid: any; name: string }[];
    preview: {
      __typename?: 'PreviewProductType';
      active: boolean;
      url?: string | null;
      description?: string | null;
      back_button_text?: string | null;
    };
  };
}

export type CoursesListQueryVariables = Exact<{
  filters?: InputMaybe<ProductListFilterInput>;
  params?: InputMaybe<ProductFilter>;
  status?: InputMaybe<StatusEnum[] | StatusEnum>;
}>;

export interface CoursesListQuery {
  __typename?: 'Query';
  productsList: {
    __typename?: 'ProductsResponse';
    count: number;
    rows: {
      __typename?: 'ProductType';
      createdAt: number;
      updatedAt: number;
      deletedAt?: number | null;
      uuid: any;
      img: string;
      name: string;
      description: string;
      successBuyText: string;
      manualBuyText: string;
      activeAcquiring: boolean;
      faq: string;
      period?: string | null;
      chanel_id: number;
      query?: string | null;
      chat_id?: number | null;
      type: ProductTypeEnum;
      purchasedCount: number;
      receipts: number;
      haveAccessCount: number;
      status: StatusEnum;
      preview: {
        __typename?: 'PreviewProductType';
        active: boolean;
        url?: string | null;
        description?: string | null;
        back_button_text?: string | null;
      };
    }[];
  };
}

export type CourseShortcodesQueryVariables = Exact<Record<string, never>>;

export interface CourseShortcodesQuery {
  __typename?: 'Query';
  productShortcodes: string[];
}

export type ArchiveCourseMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface ArchiveCourseMutation {
  __typename?: 'Mutation';
  archiveProduct: any;
}

export type PublishCourseMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface PublishCourseMutation {
  __typename?: 'Mutation';
  activateProduct: any;
}

export type RemoveCourseMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface RemoveCourseMutation {
  __typename?: 'Mutation';
  removeProduct: any;
}

export type UpdateCourseMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: ProductInput;
}>;

export interface UpdateCourseMutation {
  __typename?: 'Mutation';
  updateProduct: { __typename?: 'ProductType'; uuid: any };
}

export type CreateCourseMutationVariables = Exact<{
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  input: ProductInput;
  idTeacher: Scalars['Float']['input'];
}>;

export interface CreateCourseMutation {
  __typename?: 'Mutation';
  createProduct: { __typename?: 'ProductType'; uuid: any };
}

export type TariffQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface TariffQuery {
  __typename?: 'Query';
  tariff: {
    __typename?: 'TariffType';
    createdAt: number;
    updatedAt: number;
    uuid: any;
    name: string;
    description: string;
    price: number;
    period?: string | null;
    special_price?: number | null;
    chanel_id?: number | null;
    chat_id?: number | null;
  };
}

export type UpdateTariffMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: TariffInput;
}>;

export interface UpdateTariffMutation {
  __typename?: 'Mutation';
  updateTariff: {
    __typename?: 'TariffType';
    createdAt: number;
    updatedAt: number;
    uuid: any;
    name: string;
    description: string;
    price: number;
    period?: string | null;
    special_price?: number | null;
    chanel_id?: number | null;
    chat_id?: number | null;
  };
}

export type CreateTariffMutationVariables = Exact<{
  input: TariffInput;
}>;

export interface CreateTariffMutation {
  __typename?: 'Mutation';
  createTariff: {
    __typename?: 'TariffType';
    createdAt: number;
    updatedAt: number;
    uuid: any;
    name: string;
    description: string;
    price: number;
    period?: string | null;
    special_price?: number | null;
    chanel_id?: number | null;
    chat_id?: number | null;
  };
}

export type TeacherQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export interface TeacherQuery {
  __typename?: 'Query';
  teacher: {
    __typename?: 'TeacherType';
    createdAt: number;
    updatedAt: number;
    deletedAt?: number | null;
    id: number;
    image: string;
    name: string;
    description: string;
    telegram: string;
    telegramSocial?: string | null;
    vk?: string | null;
    facebook?: string | null;
    youtube?: string | null;
    instagram?: string | null;
    socialsText: string;
    productsListText: string;
    supplierInfo: {
      __typename?: 'TeacherSupplierInfoType';
      name?: string | null;
      inn?: string | null;
      phone?: any | null;
    };
  };
}

export type TeacherFilesQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export interface TeacherFilesQuery {
  __typename?: 'Query';
  teacher: {
    __typename?: 'TeacherType';
    files: {
      __typename?: 'TeacherFilesType';
      oferta?: string | null;
      agent?: string | null;
      policy?: string | null;
    };
  };
}

export type TeachersListQueryVariables = Exact<{
  filter?: InputMaybe<TeacherListFilterInput>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export interface TeachersListQuery {
  __typename?: 'Query';
  teachersList: {
    __typename?: 'TeachersResponse';
    count: number;
    rows: {
      __typename?: 'TeacherType';
      createdAt: number;
      id: number;
      name: string;
      telegram: string;
      productsCount: number;
      ordersCount: number;
      balance: number;
      receipts: number;
    }[];
  };
}

export type TeacherShortcodesQueryVariables = Exact<Record<string, never>>;

export interface TeacherShortcodesQuery {
  __typename?: 'Query';
  teacherShortcodes: string[];
}

export type UpdateTeacherMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  input: TeacherInput;
}>;

export interface UpdateTeacherMutation {
  __typename?: 'Mutation';
  updateTeacher: { __typename?: 'TeacherType'; id: number };
}

export type CreateTeacherMutationVariables = Exact<{
  input: TeacherInput;
}>;

export interface CreateTeacherMutation {
  __typename?: 'Mutation';
  createTeacher: { __typename?: 'TeacherType'; id: number };
}

export interface UserFieldsFragment {
  __typename?: 'UserType';
  createdAt: number;
  updatedAt: number;
  deletedAt?: number | null;
  uuid: any;
  tgId?: number | null;
  username?: string | null;
  first_name: string;
  last_name?: string | null;
  vk?: string | null;
  facebook?: string | null;
  instagram?: string | null;
  description?: string | null;
  language: LanguageEnum;
  role: RoleEnum;
  email?: any | null;
  phone?: any | null;
  ordersCount: number;
  haveAccessCount: number;
  controlTeacherId?: number | null;
  receipts: number;
  gender?: GenderEnum | null;
}

export type UserQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface UserQuery {
  __typename?: 'Query';
  user: {
    __typename?: 'UserType';
    createdAt: number;
    updatedAt: number;
    deletedAt?: number | null;
    uuid: any;
    tgId?: number | null;
    username?: string | null;
    first_name: string;
    last_name?: string | null;
    vk?: string | null;
    facebook?: string | null;
    instagram?: string | null;
    description?: string | null;
    language: LanguageEnum;
    role: RoleEnum;
    email?: any | null;
    phone?: any | null;
    ordersCount: number;
    haveAccessCount: number;
    controlTeacherId?: number | null;
    receipts: number;
    gender?: GenderEnum | null;
  };
}

export type UsersStatsQueryVariables = Exact<{
  teacherId?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export interface UsersStatsQuery {
  __typename?: 'Query';
  usersStats: {
    __typename?: 'UsersStatsType';
    count: number;
    maleCount: number;
    femaleCount: number;
  };
}

export type UsersListQueryVariables = Exact<{
  filters?: InputMaybe<UserListFilterInput>;
  teacherId?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export interface UsersListQuery {
  __typename?: 'Query';
  users: {
    __typename?: 'UsersResponse';
    count: number;
    rows: {
      __typename?: 'UserType';
      createdAt: number;
      uuid: any;
      first_name: string;
      last_name?: string | null;
      username?: string | null;
      phone?: any | null;
      email?: any | null;
      receipts: number;
      haveAccessCount: number;
      ordersCount: number;
    }[];
  };
}

export type UsersControlTeacherListQueryVariables = Exact<{
  filters?: InputMaybe<UserControlTeacherListFilterInput>;
  teacherId: Scalars['Float']['input'];
}>;

export interface UsersControlTeacherListQuery {
  __typename?: 'Query';
  usersControlTeacher: {
    __typename?: 'UsersResponse';
    count: number;
    rows: {
      __typename?: 'UserType';
      createdAt: number;
      uuid: any;
      first_name: string;
      last_name?: string | null;
      username?: string | null;
      phone?: any | null;
      email?: any | null;
      receipts: number;
      haveAccessCount: number;
      ordersCount: number;
    }[];
  };
}

export type UpdateUserMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateUserInput;
}>;

export interface UpdateUserMutation {
  __typename?: 'Mutation';
  updateUser?: { __typename?: 'UserType'; uuid: any } | null;
}

export type DeactivateUserTeacherMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface DeactivateUserTeacherMutation {
  __typename?: 'Mutation';
  deactivateUserTeacher?: { __typename?: 'UserType'; uuid: any } | null;
}

export type CreateAdminUserMutationVariables = Exact<{
  input: CreateAdminUserInput;
}>;

export interface CreateAdminUserMutation {
  __typename?: 'Mutation';
  createAdminUser: { __typename?: 'UserType'; uuid: any };
}

export interface WithdrawalFieldsFragment {
  __typename?: 'WithdrawalType';
  createdAt: number;
  updatedAt: number;
  deletedAt?: number | null;
  uuid: any;
  amount: number;
  status: WithdrawalStatusEnum;
  teacher: { __typename?: 'TeacherType'; name: string };
}

export type WithdrawalsListQueryVariables = Exact<{
  filter?: InputMaybe<WithdrawalListFilterInput>;
  teacherId: Scalars['Int']['input'];
}>;

export interface WithdrawalsListQuery {
  __typename?: 'Query';
  withdrawalsByTeacher: {
    __typename?: 'WithdrawalsResponse';
    count: number;
    rows: {
      __typename?: 'WithdrawalType';
      createdAt: number;
      updatedAt: number;
      deletedAt?: number | null;
      uuid: any;
      amount: number;
      status: WithdrawalStatusEnum;
      paymentAccount: {
        __typename?: 'PaymentAccountType';
        createdAt: number;
        updatedAt: number;
        deletedAt?: number | null;
        uuid: any;
        name: string;
        inn: string;
        bik: string;
        accountNumber: string;
      };
      teacher: { __typename?: 'TeacherType'; name: string };
    }[];
  };
}

export type WithdrawalRequestMutationVariables = Exact<{
  input: WithdrawalTeacherInput;
}>;

export interface WithdrawalRequestMutation {
  __typename?: 'Mutation';
  requestWithdrawal: any;
}

export type CancelWithdrawalMutationVariables = Exact<{
  teacherId: Scalars['Int']['input'];
  uuid: Scalars['UUID']['input'];
}>;

export interface CancelWithdrawalMutation {
  __typename?: 'Mutation';
  cancelWithdrawal: any;
}

export const JwtFieldsFragmentDoc = gql`
  fragment JwtFields on JwtType {
    access_token
    refresh_token
  }
`;
export const OrderFieldsFragmentDoc = gql`
  fragment OrderFields on OrderType {
    createdAt
    updatedAt
    deletedAt
    id
    completed
    expired_date
    price
    teacherCommission
    status
    invoice_url
  }
`;
export const PaymentAccountFieldsFragmentDoc = gql`
  fragment PaymentAccountFields on PaymentAccountType {
    createdAt
    updatedAt
    deletedAt
    uuid
    name
    inn
    bik
    accountNumber
  }
`;
export const ProductPreviewFieldsFragmentDoc = gql`
  fragment ProductPreviewFields on PreviewProductType {
    active
    url
    description
    back_button_text
  }
`;
export const ProductFieldsFragmentDoc = gql`
  fragment ProductFields on ProductType {
    createdAt
    updatedAt
    deletedAt
    uuid
    img
    name
    description
    preview {
      ...ProductPreviewFields
    }
    successBuyText(disableShortcode: true)
    manualBuyText(disableShortcode: true)
    activeAcquiring
    faq(disableShortcode: true)
    period
    chanel_id
    query
    chat_id
    type
    purchasedCount
    receipts
    haveAccessCount
    status
  }
  ${ProductPreviewFieldsFragmentDoc}
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on UserType {
    createdAt
    updatedAt
    deletedAt
    uuid
    tgId
    username
    first_name
    last_name
    vk
    facebook
    instagram
    description
    language
    role
    email
    phone
    ordersCount
    haveAccessCount
    controlTeacherId
    receipts
    gender
  }
`;
export const WithdrawalFieldsFragmentDoc = gql`
  fragment WithdrawalFields on WithdrawalType {
    createdAt
    updatedAt
    deletedAt
    uuid
    amount
    status
    teacher {
      name
    }
  }
`;
export const LoginDocument = gql`
  mutation login($login: String!, $password: String!) {
    login(input: { login: $login, password: $password }) {
      ...JwtFields
    }
  }
  ${JwtFieldsFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      login: // value for 'login'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const RefreshDocument = gql`
  mutation refresh($refresh_token: String!) {
    refresh(refresh_token: $refresh_token) {
      ...JwtFields
    }
  }
  ${JwtFieldsFragmentDoc}
`;
export type RefreshMutationFn = Apollo.MutationFunction<
  RefreshMutation,
  RefreshMutationVariables
>;

/**
 * __useRefreshMutation__
 *
 * To run a mutation, you first call `useRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshMutation, { data, loading, error }] = useRefreshMutation({
 *   variables: {
 *      refresh_token: // value for 'refresh_token'
 *   },
 * });
 */
export function useRefreshMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshMutation,
    RefreshMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<RefreshMutation, RefreshMutationVariables>(
    RefreshDocument,
    options,
  );
}
export type RefreshMutationHookResult = ReturnType<typeof useRefreshMutation>;
export type RefreshMutationResult = Apollo.MutationResult<RefreshMutation>;
export type RefreshMutationOptions = Apollo.BaseMutationOptions<
  RefreshMutation,
  RefreshMutationVariables
>;
export const MeDocument = gql`
  query me {
    me {
      uuid
      username
      first_name
      last_name
      role
      email
      phone
      controlTeacherId
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    options,
  );
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UploadImagesDocument = gql`
  mutation uploadImages($images: [Upload!]!) {
    uploadImages(image: $images) {
      url
    }
  }
`;
export type UploadImagesMutationFn = Apollo.MutationFunction<
  UploadImagesMutation,
  UploadImagesMutationVariables
>;

/**
 * __useUploadImagesMutation__
 *
 * To run a mutation, you first call `useUploadImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImagesMutation, { data, loading, error }] = useUploadImagesMutation({
 *   variables: {
 *      images: // value for 'images'
 *   },
 * });
 */
export function useUploadImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadImagesMutation,
    UploadImagesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UploadImagesMutation,
    UploadImagesMutationVariables
  >(UploadImagesDocument, options);
}
export type UploadImagesMutationHookResult = ReturnType<
  typeof useUploadImagesMutation
>;
export type UploadImagesMutationResult =
  Apollo.MutationResult<UploadImagesMutation>;
export type UploadImagesMutationOptions = Apollo.BaseMutationOptions<
  UploadImagesMutation,
  UploadImagesMutationVariables
>;
export const FinanceStatsDocument = gql`
  query financeStats($teacherId: Int!) {
    ordersStats(args: { teacherId: $teacherId }) {
      ordersCounts {
        all
      }
      financeStats {
        profit
        totalCommission
        totalReceived
        totalWithdrawals
      }
    }
    teacher(id: $teacherId) {
      balance
    }
  }
`;

/**
 * __useFinanceStatsQuery__
 *
 * To run a query within a React component, call `useFinanceStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceStatsQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useFinanceStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FinanceStatsQuery,
    FinanceStatsQueryVariables
  > &
    (
      | { variables: FinanceStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<FinanceStatsQuery, FinanceStatsQueryVariables>(
    FinanceStatsDocument,
    options,
  );
}
export function useFinanceStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinanceStatsQuery,
    FinanceStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<FinanceStatsQuery, FinanceStatsQueryVariables>(
    FinanceStatsDocument,
    options,
  );
}
export function useFinanceStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FinanceStatsQuery,
    FinanceStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<FinanceStatsQuery, FinanceStatsQueryVariables>(
    FinanceStatsDocument,
    options,
  );
}
export type FinanceStatsQueryHookResult = ReturnType<
  typeof useFinanceStatsQuery
>;
export type FinanceStatsLazyQueryHookResult = ReturnType<
  typeof useFinanceStatsLazyQuery
>;
export type FinanceStatsSuspenseQueryHookResult = ReturnType<
  typeof useFinanceStatsSuspenseQuery
>;
export type FinanceStatsQueryResult = Apollo.QueryResult<
  FinanceStatsQuery,
  FinanceStatsQueryVariables
>;
export const FinanceListsCountDocument = gql`
  query financeListsCount($teacherId: Int!) {
    ordersList(
      args: { teacherId: $teacherId }
      filters: { pagination: { pageSize: 1, page: 1 } }
      params: { status: Success }
    ) {
      count
    }
    withdrawalsByTeacher(
      idTeacher: $teacherId
      filters: { pagination: { pageSize: 1, page: 1 } }
    ) {
      count
    }
  }
`;

/**
 * __useFinanceListsCountQuery__
 *
 * To run a query within a React component, call `useFinanceListsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceListsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceListsCountQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useFinanceListsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  > &
    (
      | { variables: FinanceListsCountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  >(FinanceListsCountDocument, options);
}
export function useFinanceListsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  >(FinanceListsCountDocument, options);
}
export function useFinanceListsCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  >(FinanceListsCountDocument, options);
}
export type FinanceListsCountQueryHookResult = ReturnType<
  typeof useFinanceListsCountQuery
>;
export type FinanceListsCountLazyQueryHookResult = ReturnType<
  typeof useFinanceListsCountLazyQuery
>;
export type FinanceListsCountSuspenseQueryHookResult = ReturnType<
  typeof useFinanceListsCountSuspenseQuery
>;
export type FinanceListsCountQueryResult = Apollo.QueryResult<
  FinanceListsCountQuery,
  FinanceListsCountQueryVariables
>;
export const PurchaseStatsDocument = gql`
  query purchaseStats($teacherId: Int!, $uuidProduct: UUID) {
    ordersStats(args: { teacherId: $teacherId, uuidProduct: $uuidProduct }) {
      usersCounts {
        all
        female
        male
      }
      financeStats {
        profit
      }
    }
  }
`;

/**
 * __usePurchaseStatsQuery__
 *
 * To run a query within a React component, call `usePurchaseStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseStatsQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      uuidProduct: // value for 'uuidProduct'
 *   },
 * });
 */
export function usePurchaseStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PurchaseStatsQuery,
    PurchaseStatsQueryVariables
  > &
    (
      | { variables: PurchaseStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<PurchaseStatsQuery, PurchaseStatsQueryVariables>(
    PurchaseStatsDocument,
    options,
  );
}
export function usePurchaseStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PurchaseStatsQuery,
    PurchaseStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<PurchaseStatsQuery, PurchaseStatsQueryVariables>(
    PurchaseStatsDocument,
    options,
  );
}
export function usePurchaseStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PurchaseStatsQuery,
    PurchaseStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    PurchaseStatsQuery,
    PurchaseStatsQueryVariables
  >(PurchaseStatsDocument, options);
}
export type PurchaseStatsQueryHookResult = ReturnType<
  typeof usePurchaseStatsQuery
>;
export type PurchaseStatsLazyQueryHookResult = ReturnType<
  typeof usePurchaseStatsLazyQuery
>;
export type PurchaseStatsSuspenseQueryHookResult = ReturnType<
  typeof usePurchaseStatsSuspenseQuery
>;
export type PurchaseStatsQueryResult = Apollo.QueryResult<
  PurchaseStatsQuery,
  PurchaseStatsQueryVariables
>;
export const OrdersListDocument = gql`
  query ordersList(
    $filter: OrdersListFilterInput
    $teacherId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
    $isExpired: Boolean
  ) {
    ordersList(
      filters: $filter
      args: {
        teacherId: $teacherId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: Success, completed: $isExpired }
    ) {
      count
      rows {
        ...OrderFields
        user {
          first_name
          last_name
          email
          username
          uuid
          phone
        }
        product {
          name
          type
        }
        tariff {
          name
        }
      }
    }
  }
  ${OrderFieldsFragmentDoc}
`;

/**
 * __useOrdersListQuery__
 *
 * To run a query within a React component, call `useOrdersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      teacherId: // value for 'teacherId'
 *      uuidUser: // value for 'uuidUser'
 *      uuidProduct: // value for 'uuidProduct'
 *      isExpired: // value for 'isExpired'
 *   },
 * });
 */
export function useOrdersListQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrdersListQuery,
    OrdersListQueryVariables
  > &
    (
      | { variables: OrdersListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrdersListQuery, OrdersListQueryVariables>(
    OrdersListDocument,
    options,
  );
}
export function useOrdersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrdersListQuery,
    OrdersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrdersListQuery, OrdersListQueryVariables>(
    OrdersListDocument,
    options,
  );
}
export function useOrdersListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrdersListQuery,
    OrdersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<OrdersListQuery, OrdersListQueryVariables>(
    OrdersListDocument,
    options,
  );
}
export type OrdersListQueryHookResult = ReturnType<typeof useOrdersListQuery>;
export type OrdersListLazyQueryHookResult = ReturnType<
  typeof useOrdersListLazyQuery
>;
export type OrdersListSuspenseQueryHookResult = ReturnType<
  typeof useOrdersListSuspenseQuery
>;
export type OrdersListQueryResult = Apollo.QueryResult<
  OrdersListQuery,
  OrdersListQueryVariables
>;
export const OrdersCountDocument = gql`
  query ordersCount(
    $filter: OrdersListFilterInput
    $teacherId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
    $isExpired: Boolean
  ) {
    ordersList(
      filters: $filter
      args: {
        teacherId: $teacherId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: Success, completed: $isExpired }
    ) {
      count
    }
  }
`;

/**
 * __useOrdersCountQuery__
 *
 * To run a query within a React component, call `useOrdersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      teacherId: // value for 'teacherId'
 *      uuidUser: // value for 'uuidUser'
 *      uuidProduct: // value for 'uuidProduct'
 *      isExpired: // value for 'isExpired'
 *   },
 * });
 */
export function useOrdersCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrdersCountQuery,
    OrdersCountQueryVariables
  > &
    (
      | { variables: OrdersCountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrdersCountQuery, OrdersCountQueryVariables>(
    OrdersCountDocument,
    options,
  );
}
export function useOrdersCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrdersCountQuery,
    OrdersCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrdersCountQuery, OrdersCountQueryVariables>(
    OrdersCountDocument,
    options,
  );
}
export function useOrdersCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrdersCountQuery,
    OrdersCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<OrdersCountQuery, OrdersCountQueryVariables>(
    OrdersCountDocument,
    options,
  );
}
export type OrdersCountQueryHookResult = ReturnType<typeof useOrdersCountQuery>;
export type OrdersCountLazyQueryHookResult = ReturnType<
  typeof useOrdersCountLazyQuery
>;
export type OrdersCountSuspenseQueryHookResult = ReturnType<
  typeof useOrdersCountSuspenseQuery
>;
export type OrdersCountQueryResult = Apollo.QueryResult<
  OrdersCountQuery,
  OrdersCountQueryVariables
>;
export const OrdersAwaitSubmitListDocument = gql`
  query ordersAwaitSubmitList(
    $filter: OrdersListFilterInput
    $teacherId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
  ) {
    ordersList(
      filters: $filter
      args: {
        teacherId: $teacherId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: Pending, type: ManualPay }
    ) {
      count
      rows {
        ...OrderFields
        proof {
          file
        }
        user {
          first_name
          last_name
          email
          username
          uuid
          phone
        }
        product {
          name
          type
        }
        tariff {
          name
        }
      }
    }
  }
  ${OrderFieldsFragmentDoc}
`;

/**
 * __useOrdersAwaitSubmitListQuery__
 *
 * To run a query within a React component, call `useOrdersAwaitSubmitListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersAwaitSubmitListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersAwaitSubmitListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      teacherId: // value for 'teacherId'
 *      uuidUser: // value for 'uuidUser'
 *      uuidProduct: // value for 'uuidProduct'
 *   },
 * });
 */
export function useOrdersAwaitSubmitListQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrdersAwaitSubmitListQuery,
    OrdersAwaitSubmitListQueryVariables
  > &
    (
      | { variables: OrdersAwaitSubmitListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    OrdersAwaitSubmitListQuery,
    OrdersAwaitSubmitListQueryVariables
  >(OrdersAwaitSubmitListDocument, options);
}
export function useOrdersAwaitSubmitListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrdersAwaitSubmitListQuery,
    OrdersAwaitSubmitListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    OrdersAwaitSubmitListQuery,
    OrdersAwaitSubmitListQueryVariables
  >(OrdersAwaitSubmitListDocument, options);
}
export function useOrdersAwaitSubmitListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrdersAwaitSubmitListQuery,
    OrdersAwaitSubmitListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    OrdersAwaitSubmitListQuery,
    OrdersAwaitSubmitListQueryVariables
  >(OrdersAwaitSubmitListDocument, options);
}
export type OrdersAwaitSubmitListQueryHookResult = ReturnType<
  typeof useOrdersAwaitSubmitListQuery
>;
export type OrdersAwaitSubmitListLazyQueryHookResult = ReturnType<
  typeof useOrdersAwaitSubmitListLazyQuery
>;
export type OrdersAwaitSubmitListSuspenseQueryHookResult = ReturnType<
  typeof useOrdersAwaitSubmitListSuspenseQuery
>;
export type OrdersAwaitSubmitListQueryResult = Apollo.QueryResult<
  OrdersAwaitSubmitListQuery,
  OrdersAwaitSubmitListQueryVariables
>;
export const OrdersAwaitSubmitCountDocument = gql`
  query ordersAwaitSubmitCount(
    $teacherId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
  ) {
    ordersList(
      filters: { pagination: { pageSize: 1, page: 1 } }
      args: {
        teacherId: $teacherId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: Pending, type: ManualPay }
    ) {
      count
    }
  }
`;

/**
 * __useOrdersAwaitSubmitCountQuery__
 *
 * To run a query within a React component, call `useOrdersAwaitSubmitCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersAwaitSubmitCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersAwaitSubmitCountQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      uuidUser: // value for 'uuidUser'
 *      uuidProduct: // value for 'uuidProduct'
 *   },
 * });
 */
export function useOrdersAwaitSubmitCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrdersAwaitSubmitCountQuery,
    OrdersAwaitSubmitCountQueryVariables
  > &
    (
      | { variables: OrdersAwaitSubmitCountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    OrdersAwaitSubmitCountQuery,
    OrdersAwaitSubmitCountQueryVariables
  >(OrdersAwaitSubmitCountDocument, options);
}
export function useOrdersAwaitSubmitCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrdersAwaitSubmitCountQuery,
    OrdersAwaitSubmitCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    OrdersAwaitSubmitCountQuery,
    OrdersAwaitSubmitCountQueryVariables
  >(OrdersAwaitSubmitCountDocument, options);
}
export function useOrdersAwaitSubmitCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrdersAwaitSubmitCountQuery,
    OrdersAwaitSubmitCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    OrdersAwaitSubmitCountQuery,
    OrdersAwaitSubmitCountQueryVariables
  >(OrdersAwaitSubmitCountDocument, options);
}
export type OrdersAwaitSubmitCountQueryHookResult = ReturnType<
  typeof useOrdersAwaitSubmitCountQuery
>;
export type OrdersAwaitSubmitCountLazyQueryHookResult = ReturnType<
  typeof useOrdersAwaitSubmitCountLazyQuery
>;
export type OrdersAwaitSubmitCountSuspenseQueryHookResult = ReturnType<
  typeof useOrdersAwaitSubmitCountSuspenseQuery
>;
export type OrdersAwaitSubmitCountQueryResult = Apollo.QueryResult<
  OrdersAwaitSubmitCountQuery,
  OrdersAwaitSubmitCountQueryVariables
>;
export const SubmitManualOrderDocument = gql`
  mutation submitManualOrder($orderId: Float!) {
    submitManualOrder(orderId: $orderId) {
      id
    }
  }
`;
export type SubmitManualOrderMutationFn = Apollo.MutationFunction<
  SubmitManualOrderMutation,
  SubmitManualOrderMutationVariables
>;

/**
 * __useSubmitManualOrderMutation__
 *
 * To run a mutation, you first call `useSubmitManualOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitManualOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitManualOrderMutation, { data, loading, error }] = useSubmitManualOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useSubmitManualOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitManualOrderMutation,
    SubmitManualOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    SubmitManualOrderMutation,
    SubmitManualOrderMutationVariables
  >(SubmitManualOrderDocument, options);
}
export type SubmitManualOrderMutationHookResult = ReturnType<
  typeof useSubmitManualOrderMutation
>;
export type SubmitManualOrderMutationResult =
  Apollo.MutationResult<SubmitManualOrderMutation>;
export type SubmitManualOrderMutationOptions = Apollo.BaseMutationOptions<
  SubmitManualOrderMutation,
  SubmitManualOrderMutationVariables
>;
export const CancelManualOrderDocument = gql`
  mutation cancelManualOrder($orderId: Float!) {
    cancelManualOrder(orderId: $orderId) {
      id
    }
  }
`;
export type CancelManualOrderMutationFn = Apollo.MutationFunction<
  CancelManualOrderMutation,
  CancelManualOrderMutationVariables
>;

/**
 * __useCancelManualOrderMutation__
 *
 * To run a mutation, you first call `useCancelManualOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelManualOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelManualOrderMutation, { data, loading, error }] = useCancelManualOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCancelManualOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelManualOrderMutation,
    CancelManualOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    CancelManualOrderMutation,
    CancelManualOrderMutationVariables
  >(CancelManualOrderDocument, options);
}
export type CancelManualOrderMutationHookResult = ReturnType<
  typeof useCancelManualOrderMutation
>;
export type CancelManualOrderMutationResult =
  Apollo.MutationResult<CancelManualOrderMutation>;
export type CancelManualOrderMutationOptions = Apollo.BaseMutationOptions<
  CancelManualOrderMutation,
  CancelManualOrderMutationVariables
>;
export const PaymentAccountsListDocument = gql`
  query paymentAccountsList($teacherId: Int!) {
    paymentsByTeacher(idTeacher: $teacherId) {
      ...PaymentAccountFields
    }
  }
  ${PaymentAccountFieldsFragmentDoc}
`;

/**
 * __usePaymentAccountsListQuery__
 *
 * To run a query within a React component, call `usePaymentAccountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentAccountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentAccountsListQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function usePaymentAccountsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  > &
    (
      | { variables: PaymentAccountsListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  >(PaymentAccountsListDocument, options);
}
export function usePaymentAccountsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  >(PaymentAccountsListDocument, options);
}
export function usePaymentAccountsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  >(PaymentAccountsListDocument, options);
}
export type PaymentAccountsListQueryHookResult = ReturnType<
  typeof usePaymentAccountsListQuery
>;
export type PaymentAccountsListLazyQueryHookResult = ReturnType<
  typeof usePaymentAccountsListLazyQuery
>;
export type PaymentAccountsListSuspenseQueryHookResult = ReturnType<
  typeof usePaymentAccountsListSuspenseQuery
>;
export type PaymentAccountsListQueryResult = Apollo.QueryResult<
  PaymentAccountsListQuery,
  PaymentAccountsListQueryVariables
>;
export const BaseCourseTextsDocument = gql`
  query baseCourseTexts {
    baseTexts(
      keys: [ProductFaqText, ProductSuccessBuyText, ProductManualBuyText]
    ) {
      key
      text
    }
  }
`;

/**
 * __useBaseCourseTextsQuery__
 *
 * To run a query within a React component, call `useBaseCourseTextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseCourseTextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseCourseTextsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBaseCourseTextsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BaseCourseTextsQuery,
    BaseCourseTextsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<BaseCourseTextsQuery, BaseCourseTextsQueryVariables>(
    BaseCourseTextsDocument,
    options,
  );
}
export function useBaseCourseTextsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BaseCourseTextsQuery,
    BaseCourseTextsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    BaseCourseTextsQuery,
    BaseCourseTextsQueryVariables
  >(BaseCourseTextsDocument, options);
}
export function useBaseCourseTextsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BaseCourseTextsQuery,
    BaseCourseTextsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    BaseCourseTextsQuery,
    BaseCourseTextsQueryVariables
  >(BaseCourseTextsDocument, options);
}
export type BaseCourseTextsQueryHookResult = ReturnType<
  typeof useBaseCourseTextsQuery
>;
export type BaseCourseTextsLazyQueryHookResult = ReturnType<
  typeof useBaseCourseTextsLazyQuery
>;
export type BaseCourseTextsSuspenseQueryHookResult = ReturnType<
  typeof useBaseCourseTextsSuspenseQuery
>;
export type BaseCourseTextsQueryResult = Apollo.QueryResult<
  BaseCourseTextsQuery,
  BaseCourseTextsQueryVariables
>;
export const CoursesCountDocument = gql`
  query coursesCount($teacherId: Float) {
    productsCount(teacherId: $teacherId) {
      count
      activeCount
      archiveCount
      draftCount
    }
  }
`;

/**
 * __useCoursesCountQuery__
 *
 * To run a query within a React component, call `useCoursesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesCountQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useCoursesCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoursesCountQuery,
    CoursesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<CoursesCountQuery, CoursesCountQueryVariables>(
    CoursesCountDocument,
    options,
  );
}
export function useCoursesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoursesCountQuery,
    CoursesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<CoursesCountQuery, CoursesCountQueryVariables>(
    CoursesCountDocument,
    options,
  );
}
export function useCoursesCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CoursesCountQuery,
    CoursesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<CoursesCountQuery, CoursesCountQueryVariables>(
    CoursesCountDocument,
    options,
  );
}
export type CoursesCountQueryHookResult = ReturnType<
  typeof useCoursesCountQuery
>;
export type CoursesCountLazyQueryHookResult = ReturnType<
  typeof useCoursesCountLazyQuery
>;
export type CoursesCountSuspenseQueryHookResult = ReturnType<
  typeof useCoursesCountSuspenseQuery
>;
export type CoursesCountQueryResult = Apollo.QueryResult<
  CoursesCountQuery,
  CoursesCountQueryVariables
>;
export const CourseDocument = gql`
  query course($uuid: UUID!) {
    product(uuid: $uuid) {
      ...ProductFields
      tariffs {
        uuid
        name
      }
    }
  }
  ${ProductFieldsFragmentDoc}
`;

/**
 * __useCourseQuery__
 *
 * To run a query within a React component, call `useCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCourseQuery(
  baseOptions: Apollo.QueryHookOptions<CourseQuery, CourseQueryVariables> &
    ({ variables: CourseQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<CourseQuery, CourseQueryVariables>(
    CourseDocument,
    options,
  );
}
export function useCourseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CourseQuery, CourseQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<CourseQuery, CourseQueryVariables>(
    CourseDocument,
    options,
  );
}
export function useCourseSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CourseQuery,
    CourseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<CourseQuery, CourseQueryVariables>(
    CourseDocument,
    options,
  );
}
export type CourseQueryHookResult = ReturnType<typeof useCourseQuery>;
export type CourseLazyQueryHookResult = ReturnType<typeof useCourseLazyQuery>;
export type CourseSuspenseQueryHookResult = ReturnType<
  typeof useCourseSuspenseQuery
>;
export type CourseQueryResult = Apollo.QueryResult<
  CourseQuery,
  CourseQueryVariables
>;
export const CoursesListDocument = gql`
  query coursesList(
    $filters: ProductListFilterInput
    $params: ProductFilter
    $status: [StatusEnum!]
  ) {
    productsList(filters: $filters, params: $params, status: $status) {
      rows {
        ...ProductFields
      }
      count
    }
  }
  ${ProductFieldsFragmentDoc}
`;

/**
 * __useCoursesListQuery__
 *
 * To run a query within a React component, call `useCoursesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      params: // value for 'params'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useCoursesListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoursesListQuery,
    CoursesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<CoursesListQuery, CoursesListQueryVariables>(
    CoursesListDocument,
    options,
  );
}
export function useCoursesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoursesListQuery,
    CoursesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<CoursesListQuery, CoursesListQueryVariables>(
    CoursesListDocument,
    options,
  );
}
export function useCoursesListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CoursesListQuery,
    CoursesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<CoursesListQuery, CoursesListQueryVariables>(
    CoursesListDocument,
    options,
  );
}
export type CoursesListQueryHookResult = ReturnType<typeof useCoursesListQuery>;
export type CoursesListLazyQueryHookResult = ReturnType<
  typeof useCoursesListLazyQuery
>;
export type CoursesListSuspenseQueryHookResult = ReturnType<
  typeof useCoursesListSuspenseQuery
>;
export type CoursesListQueryResult = Apollo.QueryResult<
  CoursesListQuery,
  CoursesListQueryVariables
>;
export const CourseShortcodesDocument = gql`
  query courseShortcodes {
    productShortcodes
  }
`;

/**
 * __useCourseShortcodesQuery__
 *
 * To run a query within a React component, call `useCourseShortcodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseShortcodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseShortcodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCourseShortcodesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CourseShortcodesQuery,
    CourseShortcodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<CourseShortcodesQuery, CourseShortcodesQueryVariables>(
    CourseShortcodesDocument,
    options,
  );
}
export function useCourseShortcodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseShortcodesQuery,
    CourseShortcodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    CourseShortcodesQuery,
    CourseShortcodesQueryVariables
  >(CourseShortcodesDocument, options);
}
export function useCourseShortcodesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CourseShortcodesQuery,
    CourseShortcodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    CourseShortcodesQuery,
    CourseShortcodesQueryVariables
  >(CourseShortcodesDocument, options);
}
export type CourseShortcodesQueryHookResult = ReturnType<
  typeof useCourseShortcodesQuery
>;
export type CourseShortcodesLazyQueryHookResult = ReturnType<
  typeof useCourseShortcodesLazyQuery
>;
export type CourseShortcodesSuspenseQueryHookResult = ReturnType<
  typeof useCourseShortcodesSuspenseQuery
>;
export type CourseShortcodesQueryResult = Apollo.QueryResult<
  CourseShortcodesQuery,
  CourseShortcodesQueryVariables
>;
export const ArchiveCourseDocument = gql`
  mutation archiveCourse($uuid: UUID!) {
    archiveProduct(uuid: $uuid)
  }
`;
export type ArchiveCourseMutationFn = Apollo.MutationFunction<
  ArchiveCourseMutation,
  ArchiveCourseMutationVariables
>;

/**
 * __useArchiveCourseMutation__
 *
 * To run a mutation, you first call `useArchiveCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCourseMutation, { data, loading, error }] = useArchiveCourseMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useArchiveCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveCourseMutation,
    ArchiveCourseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    ArchiveCourseMutation,
    ArchiveCourseMutationVariables
  >(ArchiveCourseDocument, options);
}
export type ArchiveCourseMutationHookResult = ReturnType<
  typeof useArchiveCourseMutation
>;
export type ArchiveCourseMutationResult =
  Apollo.MutationResult<ArchiveCourseMutation>;
export type ArchiveCourseMutationOptions = Apollo.BaseMutationOptions<
  ArchiveCourseMutation,
  ArchiveCourseMutationVariables
>;
export const PublishCourseDocument = gql`
  mutation publishCourse($uuid: UUID!) {
    activateProduct(uuid: $uuid)
  }
`;
export type PublishCourseMutationFn = Apollo.MutationFunction<
  PublishCourseMutation,
  PublishCourseMutationVariables
>;

/**
 * __usePublishCourseMutation__
 *
 * To run a mutation, you first call `usePublishCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishCourseMutation, { data, loading, error }] = usePublishCourseMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function usePublishCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishCourseMutation,
    PublishCourseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    PublishCourseMutation,
    PublishCourseMutationVariables
  >(PublishCourseDocument, options);
}
export type PublishCourseMutationHookResult = ReturnType<
  typeof usePublishCourseMutation
>;
export type PublishCourseMutationResult =
  Apollo.MutationResult<PublishCourseMutation>;
export type PublishCourseMutationOptions = Apollo.BaseMutationOptions<
  PublishCourseMutation,
  PublishCourseMutationVariables
>;
export const RemoveCourseDocument = gql`
  mutation removeCourse($uuid: UUID!) {
    removeProduct(uuid: $uuid)
  }
`;
export type RemoveCourseMutationFn = Apollo.MutationFunction<
  RemoveCourseMutation,
  RemoveCourseMutationVariables
>;

/**
 * __useRemoveCourseMutation__
 *
 * To run a mutation, you first call `useRemoveCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCourseMutation, { data, loading, error }] = useRemoveCourseMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCourseMutation,
    RemoveCourseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    RemoveCourseMutation,
    RemoveCourseMutationVariables
  >(RemoveCourseDocument, options);
}
export type RemoveCourseMutationHookResult = ReturnType<
  typeof useRemoveCourseMutation
>;
export type RemoveCourseMutationResult =
  Apollo.MutationResult<RemoveCourseMutation>;
export type RemoveCourseMutationOptions = Apollo.BaseMutationOptions<
  RemoveCourseMutation,
  RemoveCourseMutationVariables
>;
export const UpdateCourseDocument = gql`
  mutation updateCourse($uuid: UUID!, $input: ProductInput!) {
    updateProduct(input: $input, uuid: $uuid) {
      uuid
    }
  }
`;
export type UpdateCourseMutationFn = Apollo.MutationFunction<
  UpdateCourseMutation,
  UpdateCourseMutationVariables
>;

/**
 * __useUpdateCourseMutation__
 *
 * To run a mutation, you first call `useUpdateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseMutation, { data, loading, error }] = useUpdateCourseMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCourseMutation,
    UpdateCourseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UpdateCourseMutation,
    UpdateCourseMutationVariables
  >(UpdateCourseDocument, options);
}
export type UpdateCourseMutationHookResult = ReturnType<
  typeof useUpdateCourseMutation
>;
export type UpdateCourseMutationResult =
  Apollo.MutationResult<UpdateCourseMutation>;
export type UpdateCourseMutationOptions = Apollo.BaseMutationOptions<
  UpdateCourseMutation,
  UpdateCourseMutationVariables
>;
export const CreateCourseDocument = gql`
  mutation createCourse(
    $publish: Boolean
    $input: ProductInput!
    $idTeacher: Float!
  ) {
    createProduct(publish: $publish, input: $input, idTeacher: $idTeacher) {
      uuid
    }
  }
`;
export type CreateCourseMutationFn = Apollo.MutationFunction<
  CreateCourseMutation,
  CreateCourseMutationVariables
>;

/**
 * __useCreateCourseMutation__
 *
 * To run a mutation, you first call `useCreateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseMutation, { data, loading, error }] = useCreateCourseMutation({
 *   variables: {
 *      publish: // value for 'publish'
 *      input: // value for 'input'
 *      idTeacher: // value for 'idTeacher'
 *   },
 * });
 */
export function useCreateCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCourseMutation,
    CreateCourseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    CreateCourseMutation,
    CreateCourseMutationVariables
  >(CreateCourseDocument, options);
}
export type CreateCourseMutationHookResult = ReturnType<
  typeof useCreateCourseMutation
>;
export type CreateCourseMutationResult =
  Apollo.MutationResult<CreateCourseMutation>;
export type CreateCourseMutationOptions = Apollo.BaseMutationOptions<
  CreateCourseMutation,
  CreateCourseMutationVariables
>;
export const TariffDocument = gql`
  query tariff($uuid: UUID!) {
    tariff(uuid: $uuid) {
      createdAt
      updatedAt
      uuid
      name
      description
      price
      period
      special_price
      chanel_id
      chat_id
    }
  }
`;

/**
 * __useTariffQuery__
 *
 * To run a query within a React component, call `useTariffQuery` and pass it any options that fit your needs.
 * When your component renders, `useTariffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTariffQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useTariffQuery(
  baseOptions: Apollo.QueryHookOptions<TariffQuery, TariffQueryVariables> &
    ({ variables: TariffQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<TariffQuery, TariffQueryVariables>(
    TariffDocument,
    options,
  );
}
export function useTariffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TariffQuery, TariffQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<TariffQuery, TariffQueryVariables>(
    TariffDocument,
    options,
  );
}
export function useTariffSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TariffQuery,
    TariffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<TariffQuery, TariffQueryVariables>(
    TariffDocument,
    options,
  );
}
export type TariffQueryHookResult = ReturnType<typeof useTariffQuery>;
export type TariffLazyQueryHookResult = ReturnType<typeof useTariffLazyQuery>;
export type TariffSuspenseQueryHookResult = ReturnType<
  typeof useTariffSuspenseQuery
>;
export type TariffQueryResult = Apollo.QueryResult<
  TariffQuery,
  TariffQueryVariables
>;
export const UpdateTariffDocument = gql`
  mutation updateTariff($uuid: UUID!, $input: TariffInput!) {
    updateTariff(input: $input, uuid: $uuid) {
      createdAt
      updatedAt
      uuid
      name
      description
      price
      period
      special_price
      chanel_id
      chat_id
    }
  }
`;
export type UpdateTariffMutationFn = Apollo.MutationFunction<
  UpdateTariffMutation,
  UpdateTariffMutationVariables
>;

/**
 * __useUpdateTariffMutation__
 *
 * To run a mutation, you first call `useUpdateTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTariffMutation, { data, loading, error }] = useUpdateTariffMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTariffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTariffMutation,
    UpdateTariffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UpdateTariffMutation,
    UpdateTariffMutationVariables
  >(UpdateTariffDocument, options);
}
export type UpdateTariffMutationHookResult = ReturnType<
  typeof useUpdateTariffMutation
>;
export type UpdateTariffMutationResult =
  Apollo.MutationResult<UpdateTariffMutation>;
export type UpdateTariffMutationOptions = Apollo.BaseMutationOptions<
  UpdateTariffMutation,
  UpdateTariffMutationVariables
>;
export const CreateTariffDocument = gql`
  mutation createTariff($input: TariffInput!) {
    createTariff(input: $input) {
      createdAt
      updatedAt
      uuid
      name
      description
      price
      period
      special_price
      chanel_id
      chat_id
    }
  }
`;
export type CreateTariffMutationFn = Apollo.MutationFunction<
  CreateTariffMutation,
  CreateTariffMutationVariables
>;

/**
 * __useCreateTariffMutation__
 *
 * To run a mutation, you first call `useCreateTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTariffMutation, { data, loading, error }] = useCreateTariffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTariffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTariffMutation,
    CreateTariffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    CreateTariffMutation,
    CreateTariffMutationVariables
  >(CreateTariffDocument, options);
}
export type CreateTariffMutationHookResult = ReturnType<
  typeof useCreateTariffMutation
>;
export type CreateTariffMutationResult =
  Apollo.MutationResult<CreateTariffMutation>;
export type CreateTariffMutationOptions = Apollo.BaseMutationOptions<
  CreateTariffMutation,
  CreateTariffMutationVariables
>;
export const TeacherDocument = gql`
  query teacher($id: Int!) {
    teacher(id: $id) {
      createdAt
      updatedAt
      deletedAt
      id
      image
      name
      description
      telegram
      telegramSocial
      vk
      facebook
      youtube
      instagram
      socialsText(disableShortcode: true)
      productsListText(disableShortcode: true)
      supplierInfo {
        name
        inn
        phone
      }
    }
  }
`;

/**
 * __useTeacherQuery__
 *
 * To run a query within a React component, call `useTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeacherQuery(
  baseOptions: Apollo.QueryHookOptions<TeacherQuery, TeacherQueryVariables> &
    ({ variables: TeacherQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<TeacherQuery, TeacherQueryVariables>(
    TeacherDocument,
    options,
  );
}
export function useTeacherLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeacherQuery,
    TeacherQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<TeacherQuery, TeacherQueryVariables>(
    TeacherDocument,
    options,
  );
}
export function useTeacherSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TeacherQuery,
    TeacherQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<TeacherQuery, TeacherQueryVariables>(
    TeacherDocument,
    options,
  );
}
export type TeacherQueryHookResult = ReturnType<typeof useTeacherQuery>;
export type TeacherLazyQueryHookResult = ReturnType<typeof useTeacherLazyQuery>;
export type TeacherSuspenseQueryHookResult = ReturnType<
  typeof useTeacherSuspenseQuery
>;
export type TeacherQueryResult = Apollo.QueryResult<
  TeacherQuery,
  TeacherQueryVariables
>;
export const TeacherFilesDocument = gql`
  query teacherFiles($id: Int!) {
    teacher(id: $id) {
      files {
        oferta
        agent
        policy
      }
    }
  }
`;

/**
 * __useTeacherFilesQuery__
 *
 * To run a query within a React component, call `useTeacherFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherFilesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeacherFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    TeacherFilesQuery,
    TeacherFilesQueryVariables
  > &
    (
      | { variables: TeacherFilesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<TeacherFilesQuery, TeacherFilesQueryVariables>(
    TeacherFilesDocument,
    options,
  );
}
export function useTeacherFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeacherFilesQuery,
    TeacherFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<TeacherFilesQuery, TeacherFilesQueryVariables>(
    TeacherFilesDocument,
    options,
  );
}
export function useTeacherFilesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TeacherFilesQuery,
    TeacherFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<TeacherFilesQuery, TeacherFilesQueryVariables>(
    TeacherFilesDocument,
    options,
  );
}
export type TeacherFilesQueryHookResult = ReturnType<
  typeof useTeacherFilesQuery
>;
export type TeacherFilesLazyQueryHookResult = ReturnType<
  typeof useTeacherFilesLazyQuery
>;
export type TeacherFilesSuspenseQueryHookResult = ReturnType<
  typeof useTeacherFilesSuspenseQuery
>;
export type TeacherFilesQueryResult = Apollo.QueryResult<
  TeacherFilesQuery,
  TeacherFilesQueryVariables
>;
export const TeachersListDocument = gql`
  query teachersList($filter: TeacherListFilterInput, $verified: Boolean) {
    teachersList(filters: $filter, verified: $verified) {
      rows {
        createdAt
        id
        name
        telegram
        productsCount
        ordersCount
        balance
        receipts
      }
      count
    }
  }
`;

/**
 * __useTeachersListQuery__
 *
 * To run a query within a React component, call `useTeachersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeachersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeachersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      verified: // value for 'verified'
 *   },
 * });
 */
export function useTeachersListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TeachersListQuery,
    TeachersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<TeachersListQuery, TeachersListQueryVariables>(
    TeachersListDocument,
    options,
  );
}
export function useTeachersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeachersListQuery,
    TeachersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<TeachersListQuery, TeachersListQueryVariables>(
    TeachersListDocument,
    options,
  );
}
export function useTeachersListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TeachersListQuery,
    TeachersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<TeachersListQuery, TeachersListQueryVariables>(
    TeachersListDocument,
    options,
  );
}
export type TeachersListQueryHookResult = ReturnType<
  typeof useTeachersListQuery
>;
export type TeachersListLazyQueryHookResult = ReturnType<
  typeof useTeachersListLazyQuery
>;
export type TeachersListSuspenseQueryHookResult = ReturnType<
  typeof useTeachersListSuspenseQuery
>;
export type TeachersListQueryResult = Apollo.QueryResult<
  TeachersListQuery,
  TeachersListQueryVariables
>;
export const TeacherShortcodesDocument = gql`
  query teacherShortcodes {
    teacherShortcodes
  }
`;

/**
 * __useTeacherShortcodesQuery__
 *
 * To run a query within a React component, call `useTeacherShortcodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherShortcodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherShortcodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherShortcodesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TeacherShortcodesQuery,
    TeacherShortcodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    TeacherShortcodesQuery,
    TeacherShortcodesQueryVariables
  >(TeacherShortcodesDocument, options);
}
export function useTeacherShortcodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeacherShortcodesQuery,
    TeacherShortcodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    TeacherShortcodesQuery,
    TeacherShortcodesQueryVariables
  >(TeacherShortcodesDocument, options);
}
export function useTeacherShortcodesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TeacherShortcodesQuery,
    TeacherShortcodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    TeacherShortcodesQuery,
    TeacherShortcodesQueryVariables
  >(TeacherShortcodesDocument, options);
}
export type TeacherShortcodesQueryHookResult = ReturnType<
  typeof useTeacherShortcodesQuery
>;
export type TeacherShortcodesLazyQueryHookResult = ReturnType<
  typeof useTeacherShortcodesLazyQuery
>;
export type TeacherShortcodesSuspenseQueryHookResult = ReturnType<
  typeof useTeacherShortcodesSuspenseQuery
>;
export type TeacherShortcodesQueryResult = Apollo.QueryResult<
  TeacherShortcodesQuery,
  TeacherShortcodesQueryVariables
>;
export const UpdateTeacherDocument = gql`
  mutation updateTeacher($id: Float!, $input: TeacherInput!) {
    updateTeacher(id: $id, input: $input) {
      id
    }
  }
`;
export type UpdateTeacherMutationFn = Apollo.MutationFunction<
  UpdateTeacherMutation,
  UpdateTeacherMutationVariables
>;

/**
 * __useUpdateTeacherMutation__
 *
 * To run a mutation, you first call `useUpdateTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeacherMutation, { data, loading, error }] = useUpdateTeacherMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeacherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeacherMutation,
    UpdateTeacherMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UpdateTeacherMutation,
    UpdateTeacherMutationVariables
  >(UpdateTeacherDocument, options);
}
export type UpdateTeacherMutationHookResult = ReturnType<
  typeof useUpdateTeacherMutation
>;
export type UpdateTeacherMutationResult =
  Apollo.MutationResult<UpdateTeacherMutation>;
export type UpdateTeacherMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeacherMutation,
  UpdateTeacherMutationVariables
>;
export const CreateTeacherDocument = gql`
  mutation createTeacher($input: TeacherInput!) {
    createTeacher(input: $input) {
      id
    }
  }
`;
export type CreateTeacherMutationFn = Apollo.MutationFunction<
  CreateTeacherMutation,
  CreateTeacherMutationVariables
>;

/**
 * __useCreateTeacherMutation__
 *
 * To run a mutation, you first call `useCreateTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeacherMutation, { data, loading, error }] = useCreateTeacherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeacherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTeacherMutation,
    CreateTeacherMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    CreateTeacherMutation,
    CreateTeacherMutationVariables
  >(CreateTeacherDocument, options);
}
export type CreateTeacherMutationHookResult = ReturnType<
  typeof useCreateTeacherMutation
>;
export type CreateTeacherMutationResult =
  Apollo.MutationResult<CreateTeacherMutation>;
export type CreateTeacherMutationOptions = Apollo.BaseMutationOptions<
  CreateTeacherMutation,
  CreateTeacherMutationVariables
>;
export const UserDocument = gql`
  query User($uuid: UUID!) {
    user(uuid: $uuid) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> &
    ({ variables: UserQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options,
  );
}
export function useUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options,
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<
  typeof useUserSuspenseQuery
>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersStatsDocument = gql`
  query usersStats($teacherId: Int, $isActive: Boolean) {
    usersStats(
      params: { teacherId: $teacherId }
      args: { isActive: $isActive }
    ) {
      count
      maleCount
      femaleCount
    }
  }
`;

/**
 * __useUsersStatsQuery__
 *
 * To run a query within a React component, call `useUsersStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersStatsQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUsersStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UsersStatsQuery,
    UsersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UsersStatsQuery, UsersStatsQueryVariables>(
    UsersStatsDocument,
    options,
  );
}
export function useUsersStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersStatsQuery,
    UsersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UsersStatsQuery, UsersStatsQueryVariables>(
    UsersStatsDocument,
    options,
  );
}
export function useUsersStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersStatsQuery,
    UsersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<UsersStatsQuery, UsersStatsQueryVariables>(
    UsersStatsDocument,
    options,
  );
}
export type UsersStatsQueryHookResult = ReturnType<typeof useUsersStatsQuery>;
export type UsersStatsLazyQueryHookResult = ReturnType<
  typeof useUsersStatsLazyQuery
>;
export type UsersStatsSuspenseQueryHookResult = ReturnType<
  typeof useUsersStatsSuspenseQuery
>;
export type UsersStatsQueryResult = Apollo.QueryResult<
  UsersStatsQuery,
  UsersStatsQueryVariables
>;
export const UsersListDocument = gql`
  query usersList(
    $filters: UserListFilterInput
    $teacherId: Int
    $isActive: Boolean
  ) {
    users(
      filters: $filters
      params: { teacherId: $teacherId }
      args: { isActive: $isActive }
    ) {
      rows {
        createdAt
        uuid
        first_name
        last_name
        username
        phone
        email
        receipts
        haveAccessCount
        ordersCount
      }
      count
    }
  }
`;

/**
 * __useUsersListQuery__
 *
 * To run a query within a React component, call `useUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      teacherId: // value for 'teacherId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUsersListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UsersListQuery,
    UsersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UsersListQuery, UsersListQueryVariables>(
    UsersListDocument,
    options,
  );
}
export function useUsersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersListQuery,
    UsersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UsersListQuery, UsersListQueryVariables>(
    UsersListDocument,
    options,
  );
}
export function useUsersListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersListQuery,
    UsersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<UsersListQuery, UsersListQueryVariables>(
    UsersListDocument,
    options,
  );
}
export type UsersListQueryHookResult = ReturnType<typeof useUsersListQuery>;
export type UsersListLazyQueryHookResult = ReturnType<
  typeof useUsersListLazyQuery
>;
export type UsersListSuspenseQueryHookResult = ReturnType<
  typeof useUsersListSuspenseQuery
>;
export type UsersListQueryResult = Apollo.QueryResult<
  UsersListQuery,
  UsersListQueryVariables
>;
export const UsersControlTeacherListDocument = gql`
  query usersControlTeacherList(
    $filters: UserControlTeacherListFilterInput
    $teacherId: Float!
  ) {
    usersControlTeacher(filters: $filters, teacherId: $teacherId) {
      rows {
        createdAt
        uuid
        first_name
        last_name
        username
        phone
        email
        receipts
        haveAccessCount
        ordersCount
      }
      count
    }
  }
`;

/**
 * __useUsersControlTeacherListQuery__
 *
 * To run a query within a React component, call `useUsersControlTeacherListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersControlTeacherListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersControlTeacherListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useUsersControlTeacherListQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersControlTeacherListQuery,
    UsersControlTeacherListQueryVariables
  > &
    (
      | { variables: UsersControlTeacherListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    UsersControlTeacherListQuery,
    UsersControlTeacherListQueryVariables
  >(UsersControlTeacherListDocument, options);
}
export function useUsersControlTeacherListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersControlTeacherListQuery,
    UsersControlTeacherListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    UsersControlTeacherListQuery,
    UsersControlTeacherListQueryVariables
  >(UsersControlTeacherListDocument, options);
}
export function useUsersControlTeacherListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersControlTeacherListQuery,
    UsersControlTeacherListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    UsersControlTeacherListQuery,
    UsersControlTeacherListQueryVariables
  >(UsersControlTeacherListDocument, options);
}
export type UsersControlTeacherListQueryHookResult = ReturnType<
  typeof useUsersControlTeacherListQuery
>;
export type UsersControlTeacherListLazyQueryHookResult = ReturnType<
  typeof useUsersControlTeacherListLazyQuery
>;
export type UsersControlTeacherListSuspenseQueryHookResult = ReturnType<
  typeof useUsersControlTeacherListSuspenseQuery
>;
export type UsersControlTeacherListQueryResult = Apollo.QueryResult<
  UsersControlTeacherListQuery,
  UsersControlTeacherListQueryVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($uuid: UUID!, $input: UpdateUserInput!) {
    updateUser(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const DeactivateUserTeacherDocument = gql`
  mutation deactivateUserTeacher($uuid: UUID!) {
    deactivateUserTeacher(uuid: $uuid) {
      uuid
    }
  }
`;
export type DeactivateUserTeacherMutationFn = Apollo.MutationFunction<
  DeactivateUserTeacherMutation,
  DeactivateUserTeacherMutationVariables
>;

/**
 * __useDeactivateUserTeacherMutation__
 *
 * To run a mutation, you first call `useDeactivateUserTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateUserTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateUserTeacherMutation, { data, loading, error }] = useDeactivateUserTeacherMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeactivateUserTeacherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateUserTeacherMutation,
    DeactivateUserTeacherMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    DeactivateUserTeacherMutation,
    DeactivateUserTeacherMutationVariables
  >(DeactivateUserTeacherDocument, options);
}
export type DeactivateUserTeacherMutationHookResult = ReturnType<
  typeof useDeactivateUserTeacherMutation
>;
export type DeactivateUserTeacherMutationResult =
  Apollo.MutationResult<DeactivateUserTeacherMutation>;
export type DeactivateUserTeacherMutationOptions = Apollo.BaseMutationOptions<
  DeactivateUserTeacherMutation,
  DeactivateUserTeacherMutationVariables
>;
export const CreateAdminUserDocument = gql`
  mutation createAdminUser($input: CreateAdminUserInput!) {
    createAdminUser(input: $input) {
      uuid
    }
  }
`;
export type CreateAdminUserMutationFn = Apollo.MutationFunction<
  CreateAdminUserMutation,
  CreateAdminUserMutationVariables
>;

/**
 * __useCreateAdminUserMutation__
 *
 * To run a mutation, you first call `useCreateAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminUserMutation, { data, loading, error }] = useCreateAdminUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdminUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAdminUserMutation,
    CreateAdminUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    CreateAdminUserMutation,
    CreateAdminUserMutationVariables
  >(CreateAdminUserDocument, options);
}
export type CreateAdminUserMutationHookResult = ReturnType<
  typeof useCreateAdminUserMutation
>;
export type CreateAdminUserMutationResult =
  Apollo.MutationResult<CreateAdminUserMutation>;
export type CreateAdminUserMutationOptions = Apollo.BaseMutationOptions<
  CreateAdminUserMutation,
  CreateAdminUserMutationVariables
>;
export const WithdrawalsListDocument = gql`
  query withdrawalsList($filter: WithdrawalListFilterInput, $teacherId: Int!) {
    withdrawalsByTeacher(idTeacher: $teacherId, filters: $filter) {
      count
      rows {
        ...WithdrawalFields
        paymentAccount {
          ...PaymentAccountFields
        }
      }
    }
  }
  ${WithdrawalFieldsFragmentDoc}
  ${PaymentAccountFieldsFragmentDoc}
`;

/**
 * __useWithdrawalsListQuery__
 *
 * To run a query within a React component, call `useWithdrawalsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWithdrawalsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWithdrawalsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useWithdrawalsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    WithdrawalsListQuery,
    WithdrawalsListQueryVariables
  > &
    (
      | { variables: WithdrawalsListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<WithdrawalsListQuery, WithdrawalsListQueryVariables>(
    WithdrawalsListDocument,
    options,
  );
}
export function useWithdrawalsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WithdrawalsListQuery,
    WithdrawalsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    WithdrawalsListQuery,
    WithdrawalsListQueryVariables
  >(WithdrawalsListDocument, options);
}
export function useWithdrawalsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    WithdrawalsListQuery,
    WithdrawalsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    WithdrawalsListQuery,
    WithdrawalsListQueryVariables
  >(WithdrawalsListDocument, options);
}
export type WithdrawalsListQueryHookResult = ReturnType<
  typeof useWithdrawalsListQuery
>;
export type WithdrawalsListLazyQueryHookResult = ReturnType<
  typeof useWithdrawalsListLazyQuery
>;
export type WithdrawalsListSuspenseQueryHookResult = ReturnType<
  typeof useWithdrawalsListSuspenseQuery
>;
export type WithdrawalsListQueryResult = Apollo.QueryResult<
  WithdrawalsListQuery,
  WithdrawalsListQueryVariables
>;
export const WithdrawalRequestDocument = gql`
  mutation withdrawalRequest($input: WithdrawalTeacherInput!) {
    requestWithdrawal(input: $input)
  }
`;
export type WithdrawalRequestMutationFn = Apollo.MutationFunction<
  WithdrawalRequestMutation,
  WithdrawalRequestMutationVariables
>;

/**
 * __useWithdrawalRequestMutation__
 *
 * To run a mutation, you first call `useWithdrawalRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawalRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawalRequestMutation, { data, loading, error }] = useWithdrawalRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawalRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WithdrawalRequestMutation,
    WithdrawalRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    WithdrawalRequestMutation,
    WithdrawalRequestMutationVariables
  >(WithdrawalRequestDocument, options);
}
export type WithdrawalRequestMutationHookResult = ReturnType<
  typeof useWithdrawalRequestMutation
>;
export type WithdrawalRequestMutationResult =
  Apollo.MutationResult<WithdrawalRequestMutation>;
export type WithdrawalRequestMutationOptions = Apollo.BaseMutationOptions<
  WithdrawalRequestMutation,
  WithdrawalRequestMutationVariables
>;
export const CancelWithdrawalDocument = gql`
  mutation cancelWithdrawal($teacherId: Int!, $uuid: UUID!) {
    cancelWithdrawal(idTeacher: $teacherId, uuid: $uuid)
  }
`;
export type CancelWithdrawalMutationFn = Apollo.MutationFunction<
  CancelWithdrawalMutation,
  CancelWithdrawalMutationVariables
>;

/**
 * __useCancelWithdrawalMutation__
 *
 * To run a mutation, you first call `useCancelWithdrawalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelWithdrawalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelWithdrawalMutation, { data, loading, error }] = useCancelWithdrawalMutation({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCancelWithdrawalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelWithdrawalMutation,
    CancelWithdrawalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    CancelWithdrawalMutation,
    CancelWithdrawalMutationVariables
  >(CancelWithdrawalDocument, options);
}
export type CancelWithdrawalMutationHookResult = ReturnType<
  typeof useCancelWithdrawalMutation
>;
export type CancelWithdrawalMutationResult =
  Apollo.MutationResult<CancelWithdrawalMutation>;
export type CancelWithdrawalMutationOptions = Apollo.BaseMutationOptions<
  CancelWithdrawalMutation,
  CancelWithdrawalMutationVariables
>;
export const namedOperations = {
  Fragment: {
    JwtFields: 'JwtFields',
    OrderFields: 'OrderFields',
    PaymentAccountFields: 'PaymentAccountFields',
    ProductFields: 'ProductFields',
    ProductPreviewFields: 'ProductPreviewFields',
    UserFields: 'UserFields',
    WithdrawalFields: 'WithdrawalFields',
  },
  Mutation: {
    archiveCourse: 'archiveCourse',
    cancelManualOrder: 'cancelManualOrder',
    cancelWithdrawal: 'cancelWithdrawal',
    createAdminUser: 'createAdminUser',
    createCourse: 'createCourse',
    createTariff: 'createTariff',
    createTeacher: 'createTeacher',
    deactivateUserTeacher: 'deactivateUserTeacher',
    login: 'login',
    publishCourse: 'publishCourse',
    refresh: 'refresh',
    removeCourse: 'removeCourse',
    submitManualOrder: 'submitManualOrder',
    updateCourse: 'updateCourse',
    updateTariff: 'updateTariff',
    updateTeacher: 'updateTeacher',
    updateUser: 'updateUser',
    uploadImages: 'uploadImages',
    withdrawalRequest: 'withdrawalRequest',
  },
  Query: {
    User: 'User',
    baseCourseTexts: 'baseCourseTexts',
    course: 'course',
    courseShortcodes: 'courseShortcodes',
    coursesCount: 'coursesCount',
    coursesList: 'coursesList',
    financeListsCount: 'financeListsCount',
    financeStats: 'financeStats',
    me: 'me',
    ordersAwaitSubmitCount: 'ordersAwaitSubmitCount',
    ordersAwaitSubmitList: 'ordersAwaitSubmitList',
    ordersCount: 'ordersCount',
    ordersList: 'ordersList',
    paymentAccountsList: 'paymentAccountsList',
    purchaseStats: 'purchaseStats',
    tariff: 'tariff',
    teacher: 'teacher',
    teacherFiles: 'teacherFiles',
    teacherShortcodes: 'teacherShortcodes',
    teachersList: 'teachersList',
    usersControlTeacherList: 'usersControlTeacherList',
    usersList: 'usersList',
    usersStats: 'usersStats',
    withdrawalsList: 'withdrawalsList',
  },
};

export interface PossibleTypesResultData {
  possibleTypes: Record<string, string[]>;
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    BaseGraphModel: [
      'OfflineCourseType',
      'OrderProofType',
      'OrderType',
      'PaymentAccountType',
      'ProductType',
      'TariffType',
      'TeacherType',
      'UserType',
      'WithdrawalType',
    ],
    NodeIdType: [
      'BanOrderType',
      'InvoiceManualType',
      'InvoiceType',
      'OrderType',
      'TeacherType',
    ],
    NodeUuidType: [
      'OfflineCourseType',
      'OrderProofType',
      'PaymentAccountType',
      'ProductType',
      'TariffType',
      'UserType',
      'WithdrawalType',
    ],
  },
};

export default result;
