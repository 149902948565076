import React, { FC, useState } from 'react';

import { useUsersStatsQuery } from '@/apolloGenerated';
import { Container, useTeacherId } from '@/shared';
import { ContactListFilter, ContactTable } from '@features/Contact';
import { Divider, Spacer, TabItem, Tabs } from '@letsdance/ui-kit';

export enum ContactTabs {
  All,
  Active,
}
export const ContactLists: FC = () => {
  const teacherId = useTeacherId()!;
  const { data: statsData } = useUsersStatsQuery({
    skip: !teacherId,
    variables: { teacherId },
  });
  const { data: activeStatsData } = useUsersStatsQuery({
    skip: !teacherId,
    variables: { isActive: true, teacherId },
  });
  const allCount = statsData?.usersStats.count || 0;
  const activeCount = activeStatsData?.usersStats.count || 0;
  const [tab, setTab] = useState<ContactTabs>(ContactTabs.All);
  const [filter, setFilter] = useState<{ search: string }>({ search: '' });

  return (
    <div>
      <Container>
        <Tabs initValue={tab} onChange={setTab}>
          <TabItem value={ContactTabs.All} label={`Все (${allCount})`} />
          <TabItem
            value={ContactTabs.Active}
            label={`Активные пользователи (${activeCount})`}
          />
        </Tabs>
      </Container>
      <Divider />
      <Spacer size={12} />
      <Container>
        <ContactListFilter initValue={filter} onChange={setFilter} />
        <Spacer size={4} />
        <ContactTable
          isActive={tab === ContactTabs.Active}
          searchValue={filter.search}
        />
      </Container>
    </div>
  );
};
