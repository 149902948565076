import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

import { LogoIcon, MobileLogoIcon, openSupportTg } from '@/shared';
import { Button, Typography } from '@letsdance/ui-kit';
import { onTeachersList } from '@shared/libs/utils/link';
import {
  Benefit,
  CourseAuthors,
  DanceWiki,
  Footer,
} from '@widgets/Landing/ui/MainUi';
import { Question } from '@widgets/Landing/ui/MainUi/Question/Question';

import styles from './styles.module.scss';

export const HomeView: FC = (): JSX.Element => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <div className={styles.body}>
      <header className={cx(styles.header, isDesktop && styles.container)}>
        {isDesktop ? <LogoIcon /> : <MobileLogoIcon />}
        <div className={styles.header__controls}>
          <Button variant={'outlined'} onClick={openSupportTg}>
            Связаться с нами
          </Button>
        </div>
      </header>
      <div className={styles.container}>
        <main className={styles.main}>
          <section className={styles.section}>
            <div className={styles.section__group}>
              {isDesktop && (
                <div>
                  <div className={styles.section__list}>
                    <>
                      <Typography
                        tag={'div'}
                        variant={'body-20'}
                        className={cx(
                          styles.section__card,
                          styles.section__card_accent,
                        )}>
                        Танцевальная Wikipedia
                      </Typography>
                      <Typography
                        tag={'div'}
                        variant={'body-20'}
                        color={'on-surface-primary-1'}
                        className={cx(
                          styles.section__card,
                          styles.section__card_crm,
                        )}>
                        Эксклюзивные курсы от <b>топовых танцоров</b>
                      </Typography>
                      <Typography
                        tag={'div'}
                        variant={'body-20'}
                        color={'on-surface-primary-1'}
                        className={cx(
                          styles.section__card,
                          styles.section__card_pay,
                        )}>
                        Обучение через Telegram
                      </Typography>
                      <Typography
                        tag={'div'}
                        variant={'body-20'}
                        className={cx(
                          styles.section__card,
                          styles.section__card_gradient,
                        )}>
                        Сообщество <br /> единомышленников
                      </Typography>
                    </>
                  </div>
                </div>
              )}
              <div className={styles['section__main-desc']}>
                <div className={styles.section__description}>
                  <h1 className={styles.section__title}>H2Dance</h1>
                  <Typography
                    variant={isDesktop ? 'head-32' : 'body-20'}
                    className={styles.section__subtitle}
                    color={'on-surface-primary-1'}>
                    Платформа для обучения танцам в Telegram
                  </Typography>
                  {isDesktop && (
                    <Typography
                      variant={'body-20'}
                      color={'on-surface-primary-1'}
                      className={styles.section__subtitle_small}>
                      Изучай танцы в любое время, где угодно, <br /> вместе с
                      лучшими преподавателями!
                    </Typography>
                  )}
                </div>
                <div className={styles.section__controls}>
                  <Button
                    variant={'outlined'}
                    onClick={onTeachersList}
                    size={'large'}
                    className={styles.btn_reg}>
                    Регистрация преподавателя
                  </Button>
                  <Button
                    fullWidth={!isDesktop}
                    className={styles.btn}
                    onClick={() =>
                      window.open(
                        'https://t.me/h2dance_bot',
                        '_blank',
                        'noopener,noreferrer',
                      )
                    }
                    size={'large'}>
                    Начать обучение
                  </Button>
                </div>
              </div>
            </div>
          </section>
          <Benefit />
          <DanceWiki />
          <CourseAuthors />
          <Question />
        </main>
      </div>
      <div className={isDesktop ? styles.container : ''}>
        <Footer />
      </div>
    </div>
  );
};
