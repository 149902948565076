import React, { FC } from 'react';

import { Shortcode } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface ShortcodesProps {
  items: string[];
}
export const Shortcodes: FC<ShortcodesProps> = ({ items }) => (
  <div className={styles.shortcodes}>
    {items?.map((item) => <Shortcode code={item} key={item} />)}
  </div>
);
