import React, { FC, useContext, useState } from 'react';

import { useOrdersAwaitSubmitCountQuery } from '@/apolloGenerated';
import { Container, useTeacherId } from '@/shared';
import {
  getOrdersState,
  OrdersTabs,
  PurchasesContext,
  PurchasesContextType,
  useOrdersCounts,
} from '@entities/Purchases';
import {
  AwaitSubmitOrdersTable,
  PurchasesOrdersTable,
} from '@features/Purchases';
import { Divider, Spacer, TabItem, Tabs } from '@letsdance/ui-kit';

export const PurchasesLists: FC = () => {
  const teacherId = useTeacherId()!;
  const { productUuid } = useContext<PurchasesContextType>(PurchasesContext);
  const [tab, setTab] = useState<OrdersTabs>(OrdersTabs.All);
  const { active, all, expired } = useOrdersCounts({
    uuidProduct: productUuid,
  });
  const { data } = useOrdersAwaitSubmitCountQuery({
    variables: {
      teacherId,
      uuidProduct: productUuid,
    },
  });

  return (
    <div>
      <Container>
        <Tabs initValue={tab} onChange={setTab}>
          <TabItem label={`Успешные (${all})`} value={OrdersTabs.All} />
          <TabItem label={`Активные (${active})`} value={OrdersTabs.Active} />
          <TabItem label={`Истекшие (${expired})`} value={OrdersTabs.Expired} />
          <TabItem
            label={`Ожидают подтверждения (${data?.ordersList.count || 0})`}
            value={OrdersTabs.AwaitSubmit}
          />
        </Tabs>
      </Container>
      <Divider />
      <Spacer size={12} />
      <Container>
        {tab === OrdersTabs.AwaitSubmit ? (
          <AwaitSubmitOrdersTable uuidProduct={productUuid} />
        ) : (
          <PurchasesOrdersTable
            uuidProduct={productUuid}
            state={getOrdersState(tab)}
          />
        )}
      </Container>
    </div>
  );
};
