import React, { FC } from 'react';

import {
  Button,
  Dialog,
  DialogFooter,
  WithCloseModal,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

interface ProofOrderModalProps {
  id: number;
  proof: string;
}
declare global {
  interface ModalProps {
    proofOrder: ProofOrderModalProps;
  }
}
export const ProofOrderModal: FC<WithCloseModal<ProofOrderModalProps>> = ({
  id,
  onClose,
  proof,
}): JSX.Element => (
  <Dialog
    title={`Чек об оплате билета №${id}`}
    closed
    width={500}
    footerSlot={
      <DialogFooter
        fullWidth
        endSlot={
          <Button color="secondary" fullWidth onClick={onClose}>
            Закрыть
          </Button>
        }
      />
    }>
    <div className={styles.proofModal}>
      <img className={styles.proofModal__img} src={proof} alt="proof" />
    </div>
  </Dialog>
);
