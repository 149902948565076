import { FC } from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

import { openSupportTg } from '@/shared';
import {
  Button,
  FacebookIcon,
  InstagramIcon,
  TelegramIcon,
  Typography,
  VkIcon,
} from '@letsdance/ui-kit';
import mainLogoImg from '@shared/images/main-logo.png';
import {
  BY_MESSENGERS,
  INTEGRATION,
  LOYALTY_PROGRAM,
  STATISTIC,
} from '@widgets/Landing/const/teachers-text.const';

import {
  Chart,
  CheckCircle,
  Cup,
  Doughnut,
  FavoriteHart,
  Graph,
  Happyemoji,
  PercentageSquare,
  User,
} from '../../icons';

import styles from './styles.module.scss';

export const Benefit: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <section className={styles.benefit}>
      <Typography
        variant={isDesktop ? 'head-60' : 'head-32'}
        color={'on-surface-primary-1'}>
        Преимущества {!isDesktop && <br />} и возможности
      </Typography>
      <div className={cx(styles.benefit__group, styles.benefit__group_reverse)}>
        <div className={cx(styles.benefit__group_column)}>
          <Typography
            variant={isDesktop ? 'head-32' : 'head-24'}
            color={'on-surface-primary-1'}>
            Продавайте через мессенджеры
          </Typography>
          <div className={styles['benefit__messengers-wrapper']}>
            {BY_MESSENGERS &&
              BY_MESSENGERS.map((title) => (
                <div key={title} className={styles['benefit__messengers-item']}>
                  <CheckCircle width={30} height={30} />
                  <Typography
                    variant={isDesktop ? 'body-20' : 'body-16'}
                    color={'on-surface-primary-1'}
                    className={styles.benefit__text}>
                    {title}
                  </Typography>
                </div>
              ))}
          </div>
          <MediaQuery minWidth={1088}>
            <Button variant={'outlined'} onClick={openSupportTg}>
              Узнать больше
            </Button>
          </MediaQuery>
        </div>
        <div className={styles.benefit__messengers}>
          <div className={styles.benefit__icons}>
            <TelegramIcon
              width={isDesktop ? 64 : 48}
              height={isDesktop ? 64 : 48}
            />
            <VkIcon width={isDesktop ? 64 : 48} height={isDesktop ? 64 : 48} />
            <InstagramIcon
              width={isDesktop ? 64 : 48}
              height={isDesktop ? 64 : 48}
            />
            <FacebookIcon
              width={isDesktop ? 64 : 48}
              height={isDesktop ? 64 : 48}
            />
          </div>
        </div>
      </div>
      <div className={cx(styles.benefit__group)}>
        <div
          className={cx(
            styles.benefit__messengers,
            styles.benefit__messengers_purple,
          )}>
          <div
            className={cx(styles.benefit__icons, styles.benefit__icons_purple)}>
            <Graph
              width={isDesktop ? 64 : 48}
              height={isDesktop ? 64 : 48}
              small={!isDesktop}
            />
            <Chart
              width={isDesktop ? 64 : 48}
              height={isDesktop ? 64 : 48}
              small={!isDesktop}
            />
            <Doughnut
              width={isDesktop ? 64 : 48}
              height={isDesktop ? 64 : 48}
              small={!isDesktop}
            />
            <User
              width={isDesktop ? 64 : 48}
              height={isDesktop ? 64 : 48}
              small={!isDesktop}
            />
          </div>
        </div>
        <div className={cx(styles.benefit__group_column)}>
          <Typography
            variant={isDesktop ? 'head-32' : 'head-24'}
            color={'on-surface-primary-1'}>
            Статистика и аналитика
          </Typography>
          <div className={styles['benefit__messengers-wrapper']}>
            {STATISTIC &&
              STATISTIC.map((title) => (
                <div key={title} className={styles['benefit__messengers-item']}>
                  <CheckCircle width={30} height={30} />
                  <Typography
                    variant={isDesktop ? 'body-20' : 'body-16'}
                    color={'on-surface-primary-1'}
                    className={styles.benefit__text}>
                    {title}
                  </Typography>
                </div>
              ))}
          </div>
          <MediaQuery minWidth={1088}>
            <Button variant={'outlined'} onClick={openSupportTg}>
              Узнать больше
            </Button>
          </MediaQuery>
        </div>
      </div>
      <div className={cx(styles.benefit__group, styles.benefit__group_reverse)}>
        <div className={cx(styles.benefit__group_column)}>
          <Typography
            variant={isDesktop ? 'head-32' : 'head-24'}
            color={'on-surface-primary-1'}>
            Программы лояльности
          </Typography>
          <div className={styles['benefit__messengers-wrapper']}>
            {LOYALTY_PROGRAM &&
              LOYALTY_PROGRAM.map((title) => (
                <div key={title} className={styles['benefit__messengers-item']}>
                  <CheckCircle width={30} height={30} />
                  <Typography
                    variant={isDesktop ? 'body-20' : 'body-16'}
                    color={'on-surface-primary-1'}
                    className={styles.benefit__text}>
                    {title}
                  </Typography>
                </div>
              ))}
          </div>
          <MediaQuery minWidth={1088}>
            <Button variant={'outlined'} onClick={openSupportTg}>
              Узнать больше
            </Button>
          </MediaQuery>
        </div>
        <div
          className={cx(
            styles.benefit__messengers,
            styles.benefit__messengers_blue,
          )}>
          <div
            className={cx(styles.benefit__icons, styles.benefit__icons_blue)}>
            <Cup
              width={isDesktop ? 60 : 48}
              height={isDesktop ? 60 : 48}
              small={!isDesktop}
            />
            <FavoriteHart
              width={isDesktop ? 60 : 48}
              height={isDesktop ? 60 : 48}
              small={!isDesktop}
            />
            <PercentageSquare
              width={isDesktop ? 60 : 48}
              height={isDesktop ? 60 : 48}
              small={!isDesktop}
            />
            <Happyemoji
              width={isDesktop ? 60 : 48}
              height={isDesktop ? 60 : 48}
              small={!isDesktop}
            />
          </div>
        </div>
      </div>
      <div className={cx(styles.benefit__group)}>
        <div
          className={cx(
            styles.benefit__messengers,
            styles.benefit__messengers_pink,
          )}>
          <div
            className={cx(styles.benefit__icons, styles.benefit__icons_pink)}>
            <img
              src={mainLogoImg}
              alt="logo"
              className={cx(styles.benefit__logo)}
            />
          </div>
        </div>
        <div className={cx(styles.benefit__group_column)}>
          <Typography
            variant={isDesktop ? 'head-32' : 'head-24'}
            color={'on-surface-primary-1'}>
            Интеграция
            <br />с информационным порталом
            <br />
            «Где танцевать»
          </Typography>
          <div className={styles['benefit__messengers-wrapper']}>
            {INTEGRATION &&
              INTEGRATION.map((title) => (
                <div key={title} className={styles['benefit__messengers-item']}>
                  <CheckCircle width={30} height={30} />
                  <Typography
                    variant={isDesktop ? 'body-20' : 'body-16'}
                    color={'on-surface-primary-1'}
                    className={styles.benefit__text}>
                    {title}
                  </Typography>
                </div>
              ))}
          </div>
          <MediaQuery minWidth={1088}>
            <Button variant={'outlined'} onClick={openSupportTg}>
              Узнать больше
            </Button>
          </MediaQuery>
        </div>
      </div>
    </section>
  );
};
