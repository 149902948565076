import { FC } from 'react';

import { HeartIcon } from '../HeartIcon/HeartIcon';
import { MedalIcon } from '../MedalIcon/MedalIcon';
import { StarIcon } from '../StarIcon/StarIcon';

export enum EnumQuestionsIcon {
  Heart = 'heart',
  Medal = 'medal',
  Star = 'star',
}

interface QuestionsIconProps {
  icon?: EnumQuestionsIcon;
}

export const QuestionsIcon: FC<QuestionsIconProps> = ({ icon }) => {
  if (icon === EnumQuestionsIcon.Heart) {
    return <HeartIcon />;
  }

  if (icon === EnumQuestionsIcon.Medal) {
    return <MedalIcon />;
  }

  return <StarIcon />;
};
