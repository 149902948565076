import React, { FC, useEffect } from 'react';

import { FormSection, generateRandomPassword } from '@/shared';
import {
  Button,
  NestedKeys,
  stopEvent,
  TextField,
  useForm,
} from '@letsdance/ui-kit';

export interface NewUserFormValues {
  username: string;
  password: string;
}
export interface NewUserFormProps {
  onChange(val: NewUserFormValues): void;
  errors?: Record<NestedKeys<NewUserFormValues>, string[]>;
}
export const NewUserForm: FC<NewUserFormProps> = ({ errors, onChange }) => {
  const {
    onChange: onChangeValue,
    setBatchValues,
    values,
  } = useForm<NewUserFormValues>(console.log, { password: '', username: '' });

  useEffect(() => {
    onChange(values);
  }, [values]);

  const handleGeneratePassword = () => {
    setBatchValues({ password: generateRandomPassword(14) });
  };

  return (
    <>
      <FormSection title="Основная информация">
        <div className="grid-2">
          <TextField
            label="Имя пользователя"
            size="large"
            fullWidth
            error={errors?.username}
            name="username"
            initValue={values.username}
            onChange={onChangeValue}
          />
          <TextField
            label="Пароль"
            size="large"
            fullWidth
            error={errors?.password}
            name="password"
            value={values.password}
            onChange={onChangeValue}
            appendSlot={
              <Button
                size="small"
                variant="outlined"
                onClick={(e) => {
                  stopEvent(e);
                  handleGeneratePassword();
                }}>
                Сгенерировать
              </Button>
            }
          />
        </div>
      </FormSection>
    </>
  );
};
