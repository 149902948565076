import { FC, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

import { Button, Typography } from '@letsdance/ui-kit';
import { MESSAGES_WIKI } from '@widgets/Landing/const/main-text.const';

import { CheckCircle } from '../../icons';

import styles from './styles.module.scss';

export const Benefit: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });
  const videoPlayer = useRef<HTMLVideoElement | null>(null);

  const [isPaused, setPaused] = useState<boolean>(true);

  const playVideo = async () => {
    setPaused(true);

    if (videoPlayer.current) {
      await videoPlayer.current.play();

      setPaused(videoPlayer.current.paused);
    }
  };

  return (
    <section className={styles.benefit}>
      <Typography
        variant={isDesktop ? 'head-60' : 'head-32'}
        color={'on-surface-primary-1'}>
        Преимущества {!isDesktop && <br />} и возможности
      </Typography>
      <div className={styles.benefit__container}>
        <div className={styles.benefit__wrapper}>
          {MESSAGES_WIKI.map((item) => (
            <div key={item.title} className={cx(styles.benefit__group_column)}>
              <Typography
                variant={isDesktop ? 'head-32' : 'head-24'}
                color={'on-surface-primary-1'}
                className={styles.title}>
                {item.title}
              </Typography>
              <div className={styles['benefit__messengers-wrapper']}>
                {item.messages.map((message) => (
                  <div
                    key={message}
                    className={styles['benefit__messengers-item']}>
                    <CheckCircle width={30} height={30} />
                    <Typography
                      variant={isDesktop ? 'body-20' : 'body-16'}
                      color={'on-surface-primary-1'}
                      className={styles.benefit__text}>
                      {message}
                    </Typography>
                  </div>
                ))}
              </div>
              {!isDesktop && (
                <Button
                  variant={'outlined'}
                  className={styles.btn}
                  onClick={() =>
                    window.open(
                      'https://t.me/h2dance_bot',
                      '_blank',
                      'noopener,noreferrer',
                    )
                  }>
                  Перейти в Wikipedia
                </Button>
              )}
            </div>
          ))}
        </div>
        <div className={styles.benefit__videoContainer}>
          <video
            ref={videoPlayer}
            height="710"
            loop
            preload="auto"
            playsInline
            className={styles.benefit__video}
            poster="/1015 1.png">
            <source src="/video.mp4" type="video/mp4" />
            <track kind="captions" />
          </video>
          {isPaused && (
            <button className={styles.benefit__videoButton} onClick={playVideo}>
              <svg
                width="180"
                height="180"
                viewBox="0 0 180 180"
                fill="none"
                className={styles.benefit__videoSvg}
                xmlns="http://www.w3.org/2000/svg">
                <rect
                  x="40"
                  y="40"
                  width="100"
                  height="100"
                  rx="50"
                  fill="url(#paint0_linear_3846_80429)"
                  fillOpacity="0.3"
                />
                <rect
                  x="41"
                  y="41"
                  width="98"
                  height="98"
                  rx="49"
                  stroke="white"
                  strokeOpacity="0.5"
                  strokeWidth="2"
                />
                <g opacity="0.6" filter="url(#filter0_f_3846_80429)">
                  <ellipse
                    cx="90"
                    cy="90"
                    rx="50"
                    ry="50"
                    transform="rotate(-180 90 90)"
                    fill="url(#paint1_linear_3846_80429)"
                  />
                </g>
                <path
                  d="M74 89.9998V82.8798C74 74.0398 80.26 70.4198 87.92 74.8398L94.1 78.3998L100.28 81.9598C107.94 86.3798 107.94 93.6198 100.28 98.0398L94.1 101.6L87.92 105.16C80.26 109.58 74 105.96 74 97.1198V89.9998Z"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <defs>
                  <filter
                    id="filter0_f_3846_80429"
                    x="0"
                    y="0"
                    width="180"
                    height="180"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="20"
                      result="effect1_foregroundBlur_3846_80429"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_3846_80429"
                    x1="90"
                    y1="40"
                    x2="90"
                    y2="140"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0.2" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_3846_80429"
                    x1="44.386"
                    y1="90"
                    x2="147.547"
                    y2="91.1176"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#005BFE" />
                    <stop offset="1" stopColor="#BB37E9" />
                  </linearGradient>
                </defs>
              </svg>
            </button>
          )}
        </div>
      </div>
    </section>
  );
};
