import { FC } from 'react';

export const MedalIcon: FC = () => (
  <svg
    width="180"
    height="180"
    viewBox="0 0 180 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="40"
      y="40"
      width="100"
      height="100"
      rx="50"
      fill="url(#paint0_linear_3838_41589)"
      fillOpacity="0.3"
    />
    <rect
      x="41"
      y="41"
      width="98"
      height="98"
      rx="49"
      stroke="white"
      strokeOpacity="0.5"
      strokeWidth="2"
    />
    <g opacity="0.6" filter="url(#filter0_f_3838_41589)">
      <ellipse
        cx="90"
        cy="90"
        rx="50"
        ry="50"
        transform="rotate(-180 90 90)"
        fill="url(#paint1_linear_3838_41589)"
        fillOpacity="0.7"
      />
      <path
        d="M40.5 90C40.5 62.6619 62.6619 40.5 90 40.5C117.338 40.5 139.5 62.6619 139.5 90C139.5 117.338 117.338 139.5 90 139.5C62.6619 139.5 40.5 117.338 40.5 90Z"
        stroke="black"
      />
    </g>
    <path
      d="M90 96C97.4558 96 103.5 90.1798 103.5 83C103.5 75.8203 97.4558 70 90 70C82.5442 70 76.5 75.8203 76.5 83C76.5 90.1798 82.5442 96 90 96Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.0395 93.0397L81.0195 107.8C81.0195 109.6 82.2795 110.48 83.8395 109.74L89.1996 107.2C89.6396 106.98 90.3796 106.98 90.8196 107.2L96.1996 109.74C97.7396 110.46 99.0196 109.6 99.0196 107.8V92.6797"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <filter
        id="filter0_f_3838_41589"
        x="0"
        y="0"
        width="180"
        height="180"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="20"
          result="effect1_foregroundBlur_3838_41589"
        />
      </filter>
      <linearGradient
        id="paint0_linear_3838_41589"
        x1="90"
        y1="40"
        x2="90"
        y2="140"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3838_41589"
        x1="44.386"
        y1="90"
        x2="147.547"
        y2="91.1176"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF4566" />
        <stop offset="1" stopColor="#C645F4" />
      </linearGradient>
    </defs>
  </svg>
);
