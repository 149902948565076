import { EnumQuestionsIcon } from '@widgets/Landing/ui/icons/QuestionsIcon/QuestionsIcon';

export const MESSAGES_WIKI = [
  {
    messages: [
      'Все знания﻿ по сальсе, бачате и кизомбе собраны в одном месте!  Учись с нуля или улучшай свои навыки. Доступны бесплатно 24/7.',
      'Статьи, советы по музыкальности и технике — всё для того, чтобы ты мог учиться в своём темпе и погружаться в танец максимально эффективно.',
    ],
    title: 'Бесплатная танцевальная Wikipedia',
  },
  {
    messages: [
      'На нашей платформе ты найдёшь эксклюзивные авторские курсы от ведущих преподавателей с мировым именем. Учись у лучших и перенимай опыт профессионалов!',
    ],
    title: 'Обучение от топовых танцоров',
  },
  {
    messages: [
      'Все курсы доступны в Telegram, что делает обучение мобильным и простым. Ты можешь учиться где угодно и когда угодно, просто открыв чат с курсом.',
      'Весь процесс автоматизирован. Доступ к урокам и материалам через удобные инструменты — никаких сложностей, только танцы!',
    ],
    title: 'Твой танцевальный путь в Telegram',
  },
];

export const DANCE_WIKI_CARD = [
  'Музыкальность',
  `Описание <br /> базового шага`,
  `Базовые <br /> элементы <br /> и  импровизация`,
];

export const COURSE_AUTHOR = [
  {
    avatar: '/1.jpg',
    description: [
      '✔️ Чемпионка Кубка России по Бачате  🏆',
      '✔️ Научу чувствовать своё тело',
      '✔️ Разовью уверенность в себе',
    ],
    link: 'https://t.me/h2dance_bot?start=teacher-julia_andris',
    name: 'Julia Andris',
  },
  {
    avatar: '/2.jpg',
    description: [
      '✔️ Артист и наставник по сальсе.',
      '✔️ Амбассадор мужского стиля в сальсе.',
      '✔️ Имеет 11-летний опыт преподавания.',
      '✔️ Общий танцевальный стаж составляет 16 лет.',
      '✔️ Победитель и призер международных чемпионатов по сальсе 🏆',
    ],
    link: 'https://t.me/h2dance_bot?start=teacher-og_patron',
    name: 'Ислам Ибрагимов',
  },
  {
    avatar: '/3.jpg',
    description: [
      '✔️ Преподаватели и танцоры бачаты из Москвы. Проводим свои живые занятия в России и СНГ!',
      '✔️ Расскажем всё о комфортном , техничном и музыкальном танцевании!',
    ],
    link: 'https://t.me/h2dance_bot?start=teacher-bezhanyjuliya_official',
    name: 'Бежан и Юлия',
  },
];

export const QUESTION_CARD = [
  {
    icon: EnumQuestionsIcon.Heart,
    list: [
      'Изучишь танцевальную Wikipedia',
      'Будешь учиться удобно и доступно',
      'Получишь поддержку от сообщества',
    ],
    title: 'Никогда не танцевал',
  },
  {
    icon: EnumQuestionsIcon.Medal,
    list: [
      'Освоишь уроки для любого уровня',
      'Разовьёшь ритм и музыкальность',
      'Выберешь личное обучение',
      'Получишь доступ к удобной платформе обучения',
    ],
    title: 'Начинающим танцорам',
  },
  {
    icon: EnumQuestionsIcon.Star,
    list: [
      'Пройдёшь эксклюзивные курсы от топовых танцоров',
      'Усовершенствуешь технику и импровизацию',
      'Узнаешь секреты от лучших мастеров',
      'Обсудишь новые движения с единомышленниками',
    ],
    title: 'Продолжающим танцорам',
  },
];
