import { FC } from 'react';

export const StarIcon: FC = () => (
  <svg
    width="181"
    height="180"
    viewBox="0 0 181 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="40.333"
      y="40"
      width="100"
      height="100"
      rx="50"
      fill="url(#paint0_linear_3838_41597)"
      fillOpacity="0.3"
    />
    <rect
      x="41.333"
      y="41"
      width="98"
      height="98"
      rx="49"
      stroke="white"
      strokeOpacity="0.5"
      strokeWidth="2"
    />
    <g opacity="0.6" filter="url(#filter0_f_3838_41597)">
      <ellipse
        cx="90.333"
        cy="90"
        rx="50"
        ry="50"
        transform="rotate(-180 90.333 90)"
        fill="url(#paint1_linear_3838_41597)"
        fillOpacity="0.7"
      />
      <path
        d="M40.833 90C40.833 62.6619 62.9949 40.5 90.333 40.5C117.671 40.5 139.833 62.6619 139.833 90C139.833 117.338 117.671 139.5 90.333 139.5C62.9949 139.5 40.833 117.338 40.833 90Z"
        stroke="black"
      />
    </g>
    <path
      d="M93.2818 73.744L96.635 80.4874C97.0868 81.4199 98.2997 82.3047 99.3223 82.496L105.387 83.5004C109.263 84.146 110.167 86.9677 107.384 89.7894L102.652 94.5481C101.867 95.3372 101.415 96.8915 101.677 98.0154L103.032 103.898C104.102 108.537 101.629 110.354 97.5624 107.915L91.8787 104.52C90.8561 103.898 89.1438 103.898 88.1212 104.52L82.4375 107.915C78.3709 110.33 75.8976 108.537 76.9678 103.898L78.3234 98.0154C78.5849 96.9154 78.1331 95.3611 77.3483 94.5481L72.6158 89.7894C69.8334 86.9916 70.7371 84.1699 74.6135 83.5004L80.6776 82.496C81.7002 82.3286 82.9131 81.4199 83.3649 80.4874L86.7181 73.744C88.5017 70.0853 91.4506 70.0853 93.2818 73.744Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <filter
        id="filter0_f_3838_41597"
        x="0.333008"
        y="0"
        width="180"
        height="180"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="20"
          result="effect1_foregroundBlur_3838_41597"
        />
      </filter>
      <linearGradient
        id="paint0_linear_3838_41597"
        x1="90.333"
        y1="40"
        x2="90.333"
        y2="140"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3838_41597"
        x1="44.719"
        y1="90"
        x2="147.88"
        y2="91.1176"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF4566" />
        <stop offset="1" stopColor="#C645F4" />
      </linearGradient>
    </defs>
  </svg>
);
