import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';
import moment from 'moment-timezone';

import {
  Maybe,
  OrdersAwaitSubmitListQuery,
  OrderSortKeys,
  useOrdersAwaitSubmitListQuery,
} from '@/apolloGenerated';
import {
  formatAmount,
  formatSort,
  renderCellItemValue,
  timezone,
  useModal,
  useTeacherId,
} from '@/shared';
import { RouterName } from '@app/router';
import {
  Button,
  CheckDenseIcon,
  CloseDenseIcon,
  LoaderOverlay,
  SortType,
  TableController,
} from '@letsdance/ui-kit';

const headers = [
  { hide: true, key: true, value: 'id' },
  { sortable: true, title: 'Покупка', value: 'createdAt' },
  { title: 'Курс', value: 'course' },
  { title: 'Тариф', value: 'tariff' },
  { title: 'Пользователь', value: 'user' },
  { title: 'Стоимость, ₽ ', value: 'price' },
  { title: '', value: 'actions' },
];
const pageSize = 10;

export interface PurchasesOrdersTableProps {
  uuidProduct: Maybe<string>;
}
export const AwaitSubmitOrdersTable: FC<PurchasesOrdersTableProps> = ({
  uuidProduct,
}) => {
  const openSubmitOrderModal = useModal('submitOrder');
  const openCancelOrderModal = useModal('cancelOrder');
  const openProofOrderModal = useModal('proofOrder');
  const { urlFor } = useNavigate();
  const teacherId = useTeacherId()!;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<OrderSortKeys>(OrderSortKeys.CreatedAt);

  const {
    data: curData,
    loading,
    previousData,
  } = useOrdersAwaitSubmitListQuery({
    skip: !teacherId,
    variables: {
      filter: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      teacherId,
      uuidProduct,
    },
  });
  const data = curData || previousData;

  const rowTemplate = (
    order: OrdersAwaitSubmitListQuery['ordersList']['rows'][0],
  ) => ({
    actions: (
      <div className="d-flex gap-1 justify-content-end">
        {order.proof?.file && (
          <Button
            onClick={() =>
              openProofOrderModal({ id: +order.id, proof: order.proof!.file })
            }
            variant="outlined">
            Чек
          </Button>
        )}
        <Button
          onClick={() => openSubmitOrderModal({ id: +order.id })}
          variant="outlined"
          icon
          prependIcon={() => CheckDenseIcon({ color: 'var(--success-2)' })}
        />
        <Button
          onClick={() => openCancelOrderModal({ id: +order.id })}
          variant="outlined"
          icon
          prependIcon={() => CloseDenseIcon({ color: 'var(--error-2)' })}
        />
      </div>
    ),
    course: renderCellItemValue({
      label: order.product.name,
      value: order.product.type,
    }),
    createdAt: renderCellItemValue({
      label: `ID: ${order.id}`,
      value: moment(order.createdAt).tz(timezone).format('DD MMM YYYY HH:mm'),
    }),
    id: String(order.id),
    price: formatAmount(order.price),
    tariff: order.tariff.name,
    user: renderCellItemValue({
      label: `${order.user.last_name || ''} ${order.user.first_name}`,
      onClick: () =>
        window.open(
          urlFor(RouterName.AdminContactEdit, { uuid: order.user.uuid }),
          '_blank',
        ),
      value: order.user.phone || order.user.email,
    }),
  });
  const items = useMemo(
    () => (data?.ordersList.rows || []).map(rowTemplate),
    [data],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={data?.ordersList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as OrderSortKeys);
        }}
        notResetPage
      />
    </div>
  );
};
