import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  Maybe,
  UsersListQuery,
  UserSortKeys,
  useUsersListQuery,
} from '@/apolloGenerated';
import {
  Container,
  formatAmount,
  formatSort,
  renderCellDate,
  renderCellItemValue,
  useTeacherId,
} from '@/shared';
import { RouterName } from '@app/router';
import { ContactControl } from '@features/Contact/ui/ContactControl/ContactControl';
import {
  LoaderOverlay,
  SortType,
  TableController,
  Typography,
} from '@letsdance/ui-kit';

const headers = [
  { hide: true, key: true, value: 'uuid' },
  { sortable: true, title: 'Дата регистрации', value: 'createdAt', width: 180 },
  { title: 'Фамилия/имя/ID', value: 'name' },
  { title: 'Данные', value: 'data' },
  { title: 'Покупки', value: 'orders' },
  { title: 'Доступ', value: 'access' },
  { title: 'Потрачено, ₽', value: 'receipts' },
  { title: '', value: 'settings', width: 48 },
];
const pageSize = 15;
const DEFAULT_PAGE = 1;

export interface ContactTableProps {
  isActive?: boolean;
  searchValue: string;
}

export const ContactTable: FC<ContactTableProps> = ({
  isActive,
  searchValue,
}) => {
  const { navigate } = useNavigate();
  const teacherId = useTeacherId();
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<UserSortKeys>(UserSortKeys.CreatedAt);

  useEffect(() => {
    if (isActive) {
      setPage(DEFAULT_PAGE);
    }
  }, [isActive]);

  const {
    data: curData,
    loading,
    previousData,
  } = useUsersListQuery({
    skip: !teacherId,
    variables: {
      filters: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
        search: searchValue,
      },
      isActive: isActive || null,
      teacherId: teacherId || 34,
    },
  });

  const data = curData || previousData;

  const rowTemplate = (user: UsersListQuery['users']['rows'][0]) => ({
    access: String(user.haveAccessCount),
    createdAt: renderCellDate({ time: true, timestamp: user.createdAt }),
    data: renderCellItemValue({
      label: (
        <>
          {user.username ? (
            <Typography
              variant="body-14"
              color="on-surface-primary-1"
              rel="noreferrer">
              @{user.username}
            </Typography>
          ) : (
            '-'
          )}{' '}
          {user.phone && ' / ' + user.phone}
        </>
      ),
      value: user.email,
    }),
    name: renderCellItemValue({
      label: `${user.last_name || ''} ${user.first_name}`,
      value: user.uuid,
    }),
    orders: String(user.ordersCount),
    receipts: formatAmount(user.receipts, true),
    settings: (
      <ContactControl
        uuid={user.uuid}
        username={user.username!}
        name={`${user.last_name || ''} ${user.first_name}`}
      />
    ),
    uuid: user.uuid,
  });
  const items = useMemo(
    () => (data?.users.rows || []).map((item) => rowTemplate(item)),
    [data],
  );

  return (
    <Container className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={data?.users.count}
        pageSize={pageSize}
        onChangePage={setPage}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as UserSortKeys);
        }}
        onClickRow={(e, user) =>
          navigate(RouterName.AdminContactEdit, { uuid: user.uuid })
        }
        notResetPage
      />
    </Container>
  );
};
