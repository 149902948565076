import {
  OrdersCountQueryVariables,
  useOrdersCountQuery,
} from '@/apolloGenerated';
import { useTeacherId } from '@/shared';
import { Maybe } from '@letsdance/ui-kit';

export enum OrdersTabs {
  All = 'All',
  Active = 'Active',
  Expired = 'Expired',
  AwaitSubmit = 'AwaitSubmit',
}
export interface UseOrdersCountsParams {
  uuidProduct?: Maybe<string>;
  uuidUser?: Maybe<string>;
}
export const getOrdersState = (tab: OrdersTabs) => {
  if (tab === OrdersTabs.Active) {
    return false;
  }
  if (tab === OrdersTabs.Expired) {
    return true;
  }

  return undefined;
};

export const useOrdersCounts = (params?: UseOrdersCountsParams) => {
  const teacherId = useTeacherId()!;

  const getReqOptions = (
    completed?: boolean,
  ): { variables: OrdersCountQueryVariables; skip: boolean } => ({
    skip: !teacherId,
    variables: {
      isExpired: completed,
      teacherId,
      uuidProduct: params?.uuidProduct,
      uuidUser: params?.uuidUser,
    },
  });
  const { data: ordersList } = useOrdersCountQuery(
    getReqOptions(getOrdersState(OrdersTabs.All)),
  );
  const { data: ordersListActive } = useOrdersCountQuery(
    getReqOptions(getOrdersState(OrdersTabs.Active)),
  );
  const { data: ordersListExpired } = useOrdersCountQuery(
    getReqOptions(getOrdersState(OrdersTabs.Expired)),
  );

  return {
    active: ordersListActive?.ordersList.count || 0,
    all: ordersList?.ordersList.count || 0,
    expired: ordersListExpired?.ordersList.count || 0,
  };
};
