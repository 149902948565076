import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { openSupportTg } from '@/shared';
import { ArrowRightIcon, Button, Typography } from '@letsdance/ui-kit';
import { COURSE_AUTHOR } from '@widgets/Landing/const/main-text.const';

import cls from './styles.module.scss';

export const CourseAuthors: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <section className={cls.section}>
      <header className={cls.header}>
        <Typography
          variant={isDesktop ? 'head-60' : 'head-32'}
          color={'on-surface-primary-1'}
          className={cls.title}>
          Эксклюзивные курсы от топовых танцоров
        </Typography>
        <Typography
          variant={isDesktop ? 'body-20' : 'body-16'}
          color={'on-surface-primary-1'}
          className={cls.subtitle}>
          <b>Авторские программы</b> от признанных мастеров сальсы, бачаты и
          кизомбы.
          <br /> Погружайся в мир танца с лучшими!
        </Typography>
      </header>
      <div className={cls.card}>
        {COURSE_AUTHOR.map((item) => (
          <div key={item.name} className={cls.wrapper}>
            <img src={item.avatar} alt={item.name} className={cls.img} />
            <div className={cls.group}>
              <Typography variant={'head-20'} color={'on-surface-primary-1'}>
                {item.name}
              </Typography>
              <div className={cls.group}>
                {item.description.map((text) => (
                  <Typography
                    variant={'body-16'}
                    key={text}
                    color={'on-surface-primary-1'}>
                    {text}
                  </Typography>
                ))}
              </div>
            </div>
            <Button className={cls.btn}>
              <Typography
                tag={'span'}
                variant={'body-16'}
                onClick={() =>
                  window.open(item.link, '_blank', 'noopener,noreferrer')
                }>
                Начать обучение
              </Typography>
            </Button>
          </div>
        ))}
      </div>
      <Typography
        variant={'head-24'}
        className={cls.course}
        onClick={() => {
          window.open(
            'https://t.me/h2dance_bot',
            '_blank',
            'noopener,noreferrer',
          );
        }}>
        Перейти в каталог с курсами <ArrowRightIcon />
      </Typography>
      <footer className={cls.footer}>
        <Typography
          variant={isDesktop ? 'head-32' : 'head-24'}
          className={cls.footer__title}>
          H2Dance <br /> для профессионалов
        </Typography>
        <Typography variant={'body-20'} className={cls.text24}>
          Расширенные возможности <br />
          для профессиональных танцоров <br />и преподавателей
        </Typography>
        <Button className={cls.btnInfo} onClick={openSupportTg}>
          Узнать больше
        </Button>
      </footer>
    </section>
  );
};
