import React, { FC, useEffect } from 'react';

import { useCancelManualOrderMutation } from '@/apolloGenerated';
import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface CancelOrderModalProps {
  id: number;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    cancelOrder: CancelOrderModalProps;
  }
}
export const CancelOrderModal: FC<WithCloseModal<CancelOrderModalProps>> = ({
  id,
  onClose,
  onSuccess,
}): JSX.Element => {
  const [cancelOrder, { client, data, error, loading }] =
    useCancelManualOrderMutation();

  const handleSubmit = () => {
    cancelOrder({ variables: { orderId: id } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: 'Покупка отменена',
      });
      client.cache.evict({ fieldName: 'ordersList' });
      onSuccess && onSuccess();
    }
    if (error) {
      toaster.error({
        title: 'Ошибка отмены покупки. Обратитесь за помощью в техподдержку',
      });
    }
    if (data || error) {
      onClose();
    }
  }, [data, error]);

  return (
    <Dialog
      title="Подтвердить отмену покупки?"
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <div className="grid-2">
              <Button color="secondary" fullWidth onClick={onClose}>
                Отмена
              </Button>
              <Button
                fullWidth
                onClick={handleSubmit}
                color="danger"
                disabled={loading}>
                Подтвердить
              </Button>
            </div>
          }
        />
      }
    />
  );
};
