import React, { FC } from 'react';

import { Shortcodes } from '@/shared';
import {
  PropsWithNativeTypes,
  Spacer,
  TextField,
  TextFieldProps,
} from '@letsdance/ui-kit';

export interface TextareaFieldProps {
  shortcodes?: string[];
  defaultValue?: string;
}
export const TextareaField: FC<
  PropsWithNativeTypes<TextFieldProps & TextareaFieldProps, HTMLInputElement>
> = ({ defaultValue, initValue, name, shortcodes, style, ...rest }) => (
  <div>
    {shortcodes?.length && (
      <>
        <Shortcodes items={shortcodes} />
        <Spacer size={4} />
      </>
    )}
    <TextField
      tag="textarea"
      size="large"
      id={name}
      name={name}
      initValue={initValue || defaultValue}
      style={{ height: 200, ...style }}
      fullWidth
      {...rest}
    />
  </div>
);
